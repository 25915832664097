import React, { useState, useEffect } from "react";
import "./ContactUs.scss";
import UploadIcon from '../../assets/svg/contact/uil_upload.svg';
import TextareaAutosize from '../../component/TextareaAutosize';
import axiosInstance from '../../service/axiosPublicInstance';
import { CONTACT_SUPPORT_URL, PRIORITIES_URL, SECTIONS_URL } from "../../service/API_URL";

export default function ContactUs() {
  const [formData, setFormData] = useState({
    email: "",
    section: "",
    issue: "",
    priority: "",
    attachment: null,
  });
  const [isFileAttached, setIsFileAttached] = useState(false);
  const [attachmentPreview, setAttachmentPreview] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [priorities, setPriorities] = useState([]);
  const [sections, setSections] = useState([]);

  const options = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
  const [selectedOption, setSelectedOption] = useState(options[0]);
  useEffect(() => {
    if (sections) {
      console.log("sections", sections)
    }


  }, [sections])

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [prioritiesResponse, sectionsResponse] = await Promise.all([
          axiosInstance.get(PRIORITIES_URL, { headers: { 'X-TENANT-ID': 'runn' } }),
          axiosInstance.get(SECTIONS_URL, { headers: { 'X-TENANT-ID': 'runn' } })
        ]);


        // Check if the data is an array and set it
        setPriorities(Array.isArray(prioritiesResponse?.data?.data) ? prioritiesResponse?.data?.data : []);
        console.log("Array.isArray(prioritiesResponse.data) ? prioritiesResponse?.data : []", prioritiesResponse?.data?.data)
        setSections(Array.isArray(sectionsResponse?.data?.data) ? sectionsResponse?.data?.data : []);
        console.log("Array.isArray(sectionsResponse.data) ? sectionsResponse?.data : []", sectionsResponse?.data?.data)
      } catch (error) {
        console.error("Error fetching priorities or sections:", error);
        setError("Failed to fetch priorities or sections. Please try again later.");
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      const file = files[0];
      if (file) {
        const isValidType = file.type === "image/jpeg" || file.type === "image/png";
        const isValidSize = file.size >= 1 * 1024 * 1024 && file.size <= 10 * 1024 * 1024;

        if (!isValidType || !isValidSize) {
          setError("Please upload a JPEG or PNG image between 1MB and 10MB.");
          setIsFileAttached(false);
          setAttachmentPreview("");
          return;
        } else {
          setError("");
          setIsFileAttached(true);
          // const fileURL = URL.createObjectURL(file);
          // setAttachmentPreview(fileURL);
          if (attachmentPreview) {
            URL.revokeObjectURL(attachmentPreview);
          }
          
        }
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const handleRemove = () => {
    setFormData((prevData) => ({
      ...prevData,
      attachment: null,
    }));
    setIsFileAttached(false);
    setAttachmentPreview("");
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

  
    const formDataToSend = new FormData();
    formDataToSend.append("email", formData.email);
    formDataToSend.append("section", formData.section);
    formDataToSend.append("message", formData.issue);
    formDataToSend.append("priority", formData.priority);
    // formDataToSend.append("message", formData.issue);
    
    if (formData.attachment) {
      formDataToSend.append("attachment", formData.attachment);
    }

    setLoading(true);
    try {
      const response = await axiosInstance.post(CONTACT_SUPPORT_URL, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-TENANT-ID': 'runn',
        },
      });

      console.log("Form submitted successfully", response.data);
      setFormData({
        email: "",
        section: "",
        issue: "",
        priority: "",
        attachment: null,
      });
      setIsFileAttached(false);
      setAttachmentPreview("");
      setError("");
    } catch (error) {
      console.error("Error submitting form:", error.response ? error.response.data : error.message);
      setError("There was an error submitting your form. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="" style={{backgroundColor:'#444648'}}>
      <div className="popup-content">
        <h2 className="heading-text">Contact Us</h2>
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-subtext">
            <label htmlFor="email">Email Address:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleChange}
              required
              className="custom-input-email"
            />
          </div>

          <div className="form-group-contact">
            <div className="form-item">
              <label htmlFor="section">Section:</label>
              <select
                id="section"
                name="section"
                value={formData.section}
                onChange={handleChange}
                required
                className="custom-input-email"
              >
                <option value="">Select Section</option>
                {sections?.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
              
              </select>
            </div>

            <div className="form-item">
              <label htmlFor="priority">Priority:</label>
              <select
                id="priority"
                name="priority"
                value={formData.priority}
                onChange={handleChange}
                required
                className="custom-input-email"
              >
                <option value="">Select Priority</option>


{priorities?.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}


              </select>
            </div>
          </div>

          <div className="form-subtext">
            <label htmlFor="issue">Issue:</label>
            <TextareaAutosize
              name="issue"
              value={formData.issue}
              onChange={handleChange}
              placeholder="Describe the issue"
              minRows={1}
              maxRows={3}
              className="resize-none w-full p-2 border rounded-md"
              required
            />
          </div>

          <div className="form-subtext">
            <label htmlFor="attachment">Attachment:</label>
            <div className="upload-dashline">
              <div className="file-upload">
                {isFileAttached ? (
                  <div className="attachment-container">
                    <img
                      src={attachmentPreview}
                      alt="Attachment Preview"
                      className="thumbnail-preview"
                    />
                    <button type="button" className="cross-icon-button" onClick={handleRemove}>
                      <span className="cross-icon">✖</span>
                    </button>
                  </div>
                ) : (
                  <>
                    <span className="upload-icon">
                      <img src={UploadIcon} alt="Upload Icon" />
                    </span>
                    <span className="upload-text">Image (1MB to 10MB & JPEG/PNG)</span>
                  </>
                )}
                <input
                  type="file"
                  id="attachment"
                  name="attachment"
                  onChange={handleChange}
                  style={{ display: 'none' }}
                  required
                />
                {!isFileAttached && (
                  <label className="browse-button" htmlFor="attachment">Browse</label>
                )}
              </div>
              {error && <p className="error-message">{error}</p>}
            </div>
          </div>

          <div>
            <button type="submit" className="submit-btn" disabled={loading}>
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
