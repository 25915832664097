import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "../../assets/css/style.scss";
import LoginHeader from "../../component/Login-header";
import lock_outline from "../../assets/svg/login/passwordIcon.svg";
import RunnBackground from "../../assets/svg/login/backgroundRunn.svg";
import { image_url } from "../../service/Constant";
import logo from "../../assets/images/logo.png";
import { ResetPassword_URL } from "../../service/API_URL";
import { postData } from "../../api/apiMethod";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [storeFrontAccess, setStoreFrontAccess] = useState("");
  const navigate = useNavigate(); 
  const {
    appThemeReducer: { appTheme },
    adminModeReducer: { adminMode },
  } = useSelector((state) => state);

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    validatePassword(newPassword, confirmPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    const newConfirmPassword = event.target.value;
    setConfirmPassword(newConfirmPassword);
    validatePassword(password, newConfirmPassword);
  };

  const validatePassword = (newPassword, newConfirmPassword) => {
    if (
      newPassword.length < 8 ||
      !/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(newPassword)
    ) {
      setPasswordError(
        "Password must contain a minimum of 8 characters and at least one symbol e.g. @, !"
      );
    } else if (newPassword !== newConfirmPassword) {
      setPasswordError("The passwords did not match. Please try again");
    } else {
      setPasswordError("");
    }
  };

  const accessStore = () => {
    const tenantLocalData = localStorage.getItem("tenantInfo");
    if (tenantLocalData) {
      const tenantLocalDataParse = JSON.parse(tenantLocalData);
      setStoreFrontAccess(tenantLocalDataParse?.superTenant);
    }
  };

  const ResetPasswordAPI = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    const email = urlParams.get("email");

    if (!token || !email) {
      alert("Token or email is missing in the reset link");
      return;
    }

    const data = {
      email,
      password,
      confirmPassword,
      token,
    };

    try {
      const response = await postData(
        ResetPassword_URL,
        data,
        {
          "Content-Type": "application/json",
        }
      );

      if (response?.data?.success) {
        alert("Password reset successfully");
        navigate("/login");
      } else {
        alert(response?.data?.message || "Failed to reset password");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      alert("Error: Link expired or invalid. Please try again.");
    }
  };

  const isSubmitDisabled = passwordError !== "" || !password || !confirmPassword;

  useEffect(() => {
    accessStore();
  }, []);

  return (
    <>
      <LoginHeader />
      <img src={RunnBackground} alt="Background" className="bottom-background" />
      <div
        className="main"
        style={{
          backgroundColor: "#131313",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
        }}
      >
        <div className="login-block" style={{ position: "relative", zIndex: 1 }}>
          <div className="login-form" style={{ backgroundColor: "#1A1A1A" }}>
            <div
              className="title"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                className="logo"
                style={{
                  backgroundImage: `url(${
                    storeFrontAccess?.darkThemeLogoImagePath
                      ? image_url + storeFrontAccess?.darkThemeLogoImagePath
                      : logo
                  })`,
                  width: "335px",
                  height: "80px",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              />
            </div>
            <div className="title">
              <h2 style={{ color: "#fff" }}>Reset password</h2>
            </div>

            <div className="form-group">
              <label className="form-label text-white">New Password</label>
              <div
                className="input-group input-group-sm"
                style={{ backgroundColor: "#444648" }}
              >
                <div className="input-group-prepend">
                  <img src={lock_outline} alt="" />
                </div>
                <input
                  type="password"
                  placeholder="Minimum 8 characters"
                  className="form-control"
                  value={password}
                  onChange={handlePasswordChange}
                  style={{ backgroundColor: "#444648" }}
                />
              </div>
            </div>

            <div className="form-group">
              <label className="text-white">Confirm new password</label>
              <div
                className="input-group input-group-sm"
                style={{ backgroundColor: "#444648" }}
              >
                <div className="input-group-prepend">
                  <img src={lock_outline} alt="" />
                </div>
                <input
                  type="password"
                  placeholder="Confirm new password"
                  className="form-control"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  style={{ backgroundColor: "#444648" }}
                />
              </div>
              {passwordError && <span className="error">{passwordError}</span>}
            </div>

            <button
              disabled={isSubmitDisabled}
              onClick={ResetPasswordAPI}
              className="btn btn-block"
            >
              Reset password
            </button>

            <div className="retun-block">
              <p>
                Return to <a href="/">Login !</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
