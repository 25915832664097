export const baseURL = process.env.REACT_APP_API_BASE_URL || "https://cms-dev-api.runn.tv/runtv/v1";
export const cloudFront_URL = process.env.REACT_APP_CDN_URL || "https://d1lq7wiqj91e0r.cloudfront.net/";

console.log("************************** REACT_APP_ENV:", process.env.REACT_APP_ENV)
console.log("************************** baseUrl:", baseURL)
console.log("************************** cloudFront_URL:", cloudFront_URL)


export const Login_URL = `${baseURL}/auth/login`;
export const ForgetPassword_URL = `${baseURL}/auth/password/resetlink`;
export const ResetPassword_URL = `${baseURL}/auth/password/reset`;
export const RefreshToken_URL = `${baseURL}/auth/refresh`;
export const ViewershipTablehead_URL = `${baseURL}/dashboard/views`;
export const ViewershipTableChild_URL = `${baseURL}/dashboard/shows`;
export const ViewershipMap_URL = `${baseURL}/dashboard/map`;
export const Logout_URL = `${baseURL}/user/logout`;
export const CMS_upload = `${baseURL}/assets/upload`;
export const CMS_Multipart_upload = `${baseURL}/programs`;
export const CMS_Multipart_Fillers = `${baseURL}/fillers`;
export const CMS_Multipart_PROMOS = `${baseURL}/promos`;

export const Get_User = `${baseURL}/users`;
export const Create_User = `${baseURL}/auth/register`;
export const Update_User = `${baseURL}/users`;

export const Get_Roles = `${baseURL}/roles`;

export const Programs_listing_url = `${baseURL}/programs/filter`;
export const Metaupdate_url = `${baseURL}/metadata/file`;
export const Metadfile_listing_url = `${baseURL}/metadata/files`;

export const CMS_Multipart_upload_URL = `${baseURL}/multipart-upload/urls`;
export const CMS_Multipart_Complete_URL = `${baseURL}/multipart-upload/complete`;
export const Super_Tenant_URL = `${baseURL}/super-tenants`;
export const Tenant_URL = `${baseURL}/tenants`;
export const GET_METADATA = `${baseURL}/metadata`;
export const POST_METADATA_BULK = `${baseURL}/metadata/bulk`;
export const METADATA_SCHEMA = `${baseURL}/metadata-schemes`;
export const VALIDATION_SCHEMA = `${baseURL}/validation-schemes`;
export const Configdata_URL = `${baseURL}/tenants/config/options`;
export const Partnerlist_URL = `${baseURL}/partners/filter`;
export const Gettheme_URL = `${baseURL}/themes/fetchAll`;
export const Partnersearch_URL = `${baseURL}/partners/search`;
export const Partnerdelete_URL = `${baseURL}/partners`;
export const Partnerupdate_URL = `${baseURL}/partners`;
export const ScheduleSync_URL = `${baseURL}/channels/`;
export const Partnercreate_URL = `${baseURL}/partners`;
export const Themecreate_URL = `${baseURL}/themes/create`;
export const Themeupdate_URL = `${baseURL}/themes/update`;
export const FillerList_URL = `${baseURL}/fillers`;
export const FillerListfilter_URL = `${baseURL}/fillers/filter`;
export const PromosList_URL = `${baseURL}/promos/filter`;
export const FillerDelete_URL = `${baseURL}/fillers`;
export const PROMODelete_URL = `${baseURL}/promos`;

export const ThemeDelete_URL = `${baseURL}/themes/delete`;
export const AssetPartner_URL = `${baseURL}/assets/filter`;
export const AssetDelete_URL = `${baseURL}/programs`;
export const Devicetype_URL = `${baseURL}/master-data/get-devices`;

export const Categories_URL = `${baseURL}/category/fetchAll`;
export const Categoriecreate_URL = `${baseURL}/category/create`;
export const CategoriesDelete_URL = `${baseURL}/category/delete`;
export const Categoriesupdate_URL = `${baseURL}/category/update`;
export const Timezone_URL = `${baseURL}/master-data/timezones`;
export const Genres_URL = `${baseURL}/genre/fetchAll`;
export const Genrescreate_URL = `${baseURL}/genre/create`;
export const GenresDelete_URL = `${baseURL}/genre/delete`;
export const Genresupdate_URL = `${baseURL}/genre/update`;

export const Languages_list_URL = `${baseURL}/language/fetchAll`;
export const Languagescreate_URL = `${baseURL}/language/create`;
export const LanguagesDelete_URL = `${baseURL}/language/delete`;
export const Languagesupdate_URL = `${baseURL}/language/update`;

export const language_List_URL = `${baseURL}/master-data/iso-languages`;

export const MatuarityRatings_ListData_URL = `${baseURL}/maturity-rating/fetchAll`;
export const MatuarityRatingscreate_URL = `${baseURL}/maturity-rating/create`;
export const MatuarityRatingsupdate_URL = `${baseURL}/maturity-rating/update`;
export const MatuarityRatingsDelete_URL = `${baseURL}/maturity-rating/delete`;

export const Devices_list_URL = `${baseURL}/device/fetchAll`;
export const Devicescreate_URL = `${baseURL}/device/create`;
export const Devicesupdate_URL = `${baseURL}/device/update`;
export const DevicesDelete_URL = `${baseURL}/device/delete`;

export const AssetTranscode_URL = `${baseURL}/assets/transcode`;
export const AssetValidate_URL = `${baseURL}/assets/validate`;
export const AssetPublish_URL = `${baseURL}/assets/publish`;
export const AssetPoster_URL = `${baseURL}/posters`;
export const Assetoverride_URL = `${baseURL}/assets`;
export const Metadataupdate_URL = `${baseURL}/metadata`;
export const ScheduleUpload_URL = `${baseURL}/channels`;

export const Metadatbulk_URL = `${baseURL}/metadata/bulk`;

export const SaveSuperTenant_URL = `${baseURL}/super-tenants`;
export const SaveTenant_URL = `${baseURL}/tenants`;

export const DeletePoster_URL = `${baseURL}/posters`;
export const DeleteallPoster_URL = `${baseURL}/programs`;
export const AssetsSearch_URL = `${baseURL}/assets/search`;
// export const cloudFront_URL = "https://d1dhdqub2v91vk.cloudfront.net/";
export const GetChannelList_URL = `${baseURL}/channel/fetchAll`;
export const GetChannelSchemes_URL = `${baseURL}/channel-schemes`;
export const CreateChannel_URL = `${baseURL}/channel/create`;
export const UpdateChannel_URL = `${baseURL}/channel/update`;
export const ActiveInactiveChannel_URL = `${baseURL}/channel/active-deactivate`;
export const GetChannelTypeList_URL = `${baseURL}/master-data/list-channel-type`;
export const GetChannelSchedule = `${baseURL}/channel-schedules/search-assets`;
export const GetDataSchedule = `${baseURL}/channels`;
export const PutDataSchedule = `${baseURL}/program-Schedule/multi-program-ad-update`;
export const GetLangList_URL = `${baseURL}/language/fetchAll`;
export const AllCountries_URL = `https://restcountries.com/v3.1/all`;
export const TerritoriesList_URL = `${baseURL}/master-data/territories`;
export const DeleteChannel_URL = `${baseURL}/channel/delete`;
export const GetChannelById_URL = `${baseURL}/channel/getById`;

export const GetScheduleChannelList = `${baseURL}/channels/schedule-summary`;
export const GetMonitorChannelList = `${baseURL}/playout/monitor/channels`;

export const GetThemeSequenceList_URL = `${baseURL}/theme-sequences/list`;
export const SetThemeRepositon_URL = `${baseURL}/theme-sequences/update`;
export const GetGenreSequenceList_URL = `${baseURL}/genre-sequences/list`;
export const SetGenreRepositon_URL = `${baseURL}/genre-sequences/update`;
export const GetLanguageSequenceList_URL = `${baseURL}/language-sequences/list`;
export const SetLanguageRepositon_URL = `${baseURL}/language-sequences/update`;
export const GetCategorySequenceList_URL = `${baseURL}/category-sequences/list`;
export const SetCategoryRepositon_URL = `${baseURL}/category-sequences/update`;

export const GetChannelSequenceList_URL = `${baseURL}/channel-sequences/list`;
export const GetAvailableChannelList_URL = `${baseURL}/channel-sequences/search-channels`;
export const UpdateChannelSequenceList_URL = `${baseURL}/channel-sequences/update`;
export const GetFeaturedChannelList_URL = `${baseURL}/featured-channels/list`;
export const UpdateFeaturedChannelList_URL = `${baseURL}/featured-channels/update`;
export const GetBannerList_URL = `${baseURL}/banners`;
export const CreateBanner_URL = `${baseURL}/banners`;
export const GetChannelFilterList_URL = `${baseURL}/channel-filters/list`;
export const UpdateChannelFilter_URL = `${baseURL}/channel-filters/update`;

export const GetSpotlightContentList_URL = `${baseURL}/spotlight-contents/list`;
export const GetAvailableSpotlightContentList_URL = `${baseURL}/spotlight-contents/search-programs`;
export const UpdateSpotlightContentList_URL = `${baseURL}/spotlight-contents/update`;
export const browseS3 = `${baseURL}/partners`;
export const findTenantUrl = `${baseURL}/tenants/find`;
export const GetEpgHandlers =`${baseURL}/master-data/epg-handlers`;
export const getDeviceType_URL = `${baseURL}/store-devices`;
export const GetPocketFilms_URL = `${baseURL}/metadata/external`
export const SetLangPriority = `${baseURL}/language/`;
export const GetPeerGroups = `${baseURL}/master-data/peer-groups`;
export const GetUserGroup =  `${baseURL}/master-data/user-groups`;
export const CreateAvatar = `${baseURL}/avatars`;
export const GetAvatarList_URL = `${baseURL}/avatars`;
export const DeleteAvatar_URL = `${baseURL}/avatars`;
export const UpdateAvatar_URL = `${baseURL}/avatars`;
export const GetAvatarGender_URL = `${baseURL}/master-data/genders`;
export const GetAuditLogs_URL = `${baseURL}/audit-logs/filter`;
export const GeneratePartnerUrl_URL = `${baseURL}/channel`;
export const GetPartnerUrls_URL = `${baseURL}/channel`;
export const DeletePartnerUrl_URL = `${baseURL}/channel`;
export const CONTACT_SUPPORT_URL = `${baseURL}/support/contact`;
export const PRIORITIES_URL = `${baseURL}/support/priorities`;
export const SECTIONS_URL  = `${baseURL}/support/sections`;
export const ProgramValidate_URL = `${baseURL}/channels/validate/metadata/decode/PROGRAM`;
export const AdValidate_URL = `${baseURL}/channels/validate/metadata/decode/AD`;