import React, { useState, useEffect, useRef } from 'react';

import "../../pages/asset-management/channeldetail/CmsChannelDetail.scss";
// import './metastyle.scss';
import { Metadataupdate } from '../../api/api';
import { image_url } from "../../service/Constant";
import Groupicon from "../../assets/svg/partitioncontent/blueVideoImg.svg";
import expand from "../../assets/images/expand.svg";
import { posterimage } from '../../service/commonFunc';
import { JSONType } from '@aws-sdk/client-s3';

function SearchView({ searchdata, videodata, closepartition, assetdetail, searchQuery, programVideos, databymeta }) {
  // Define state variables for input values
  // const metadata = videodata.metadata;
  // console.log("searchdata",searchdata?.data);
  const [programVideo, setprogramVideo] = useState(searchdata?.data?.programs);
  const [postersVideo, setpostersVideo] = useState(searchdata?.data?.posters);
  const [promosVideo, setpromosVideo] = useState(searchdata?.data?.program);
  const [secondariesVideo, setsecondariesVideo] = useState(searchdata?.data?.secondaries);


  const hello = () => {
    if (searchdata) { }
  }

  return (
    <div className=" bg-light row">
      <div className="breadcrumbs">
        <ul>
          <li>
            <a href='/asset'>Asset Management</a>
          </li>
          <li className="active">Search</li>
        </ul>
      </div>
      <div className="video-container">
        <div className="video-title">Search results for “{searchQuery}”
          <p>Search Results : 18</p></div>
        <button className="image-container" style={{ marginLeft: "auto" }} onClick={() => closepartition()}>
          <img
            src={expand}
            className="image"
          />
        </button>

      </div>

      <div className='col-10 scroll-height'>
        <div className=''>
          <div>
            <div><h3 className='text-center right-head'> Video </h3></div>
            <div className='video-channel-list meta'>    {programVideo &&
              programVideo?.map((data) => {
                let image = posterimage(data)
                return (
                  <div className="block" onClick={() => databymeta(data)} >

                    {data?.posters?.length == 0 && image !="" ?
                      <div className="img-block " style={{ overflow: "hidden", width: "100%" }}><img src={Groupicon} alt="" /></div> :
                      <div className="img-block p-0" style={{ overflow: "hidden" }}><img style={{ width: "100%", height: "auto" }}
                       src={image } alt="" /></div>}

                    <div className="text-block"><label>{data?.metadata == null ? data?.filename : data?.metadata?.title}</label></div>
                    {/* <div className="dropdown">
                                <a className="dropdown-button"><img src={option} /></a>
                                <div className="dropdown-content">
                                  <a className="delete-button" onClick={(e) => opendata(data, true, e)}><span className="img-box"><img src={uploadicon} /></span>Upload poster</a>
                                  <a className="delete-button" ><span className="img-box"><img src={archiveicon} /></span>Archive</a>
                                  <a className="delete-button" onClick={() => assetdelete(data.id)}><span className="img-box"><img src={deleteicon} /></span> Delete</a>
                                </div>
                              </div> */}
                  </div>

                );
              })}

            </div>
          </div>
          <div>
            <div><h3 className='text-center right-head'> posters </h3></div>
            <div className='video-channel-list meta'>    {postersVideo &&
              postersVideo?.map((data) => {
                return (
                  <div className="block" onClick={() => databymeta(data)} >

                    {/* {data?.posters?.length == 0 ?
                                <div className="img-block " style={{ overflow: "hidden", width: "100%" }}><img src={Groupicon} alt="" /></div> :
                                <div className="img-block p-0" style={{ overflow: "hidden" }}><img style={{ width: "100%", height: "auto" }} src={image_url + data?.posters[0]?.sourcePath} alt="" /></div>} */}

                    <div className="text-block"><label>{data?.metadata == null ? data.filename : data?.metadata?.title == null ? data.filename : data?.metadata?.title}</label></div>
                    {/* <div className="dropdown">
                                <a className="dropdown-button"><img src={option} /></a>
                                <div className="dropdown-content">
                                  <a className="delete-button" onClick={(e) => opendata(data, true, e)}><span className="img-box"><img src={uploadicon} /></span>Upload poster</a>
                                  <a className="delete-button" ><span className="img-box"><img src={archiveicon} /></span>Archive</a>
                                  <a className="delete-button" onClick={() => assetdelete(data.id)}><span className="img-box"><img src={deleteicon} /></span> Delete</a>
                                </div>
                              </div> */}
                  </div>

                );
              })}

            </div>
          </div>
          <div>
            <div><h3 className='text-center right-head'> promos </h3></div>
            <div className='video-channel-list meta'>    {promosVideo &&
              promosVideo?.map((data) => {
                return (
                  <div className="block" onClick={() => databymeta(data)} >

                    {/* {data?.posters?.length == 0 ?
                                <div className="img-block " style={{ overflow: "hidden", width: "100%" }}><img src={Groupicon} alt="" /></div> :
                                <div className="img-block p-0" style={{ overflow: "hidden" }}><img style={{ width: "100%", height: "auto" }} src={image_url + data?.posters[0]?.sourcePath} alt="" /></div>} */}

                    <div className="text-block"><label>{data?.metadata == null ? data?.filename : data?.metadata?.title}</label></div>
                    {/* <div className="dropdown">
                                <a className="dropdown-button"><img src={option} /></a>
                                <div className="dropdown-content">
                                  <a className="delete-button" onClick={(e) => opendata(data, true, e)}><span className="img-box"><img src={uploadicon} /></span>Upload poster</a>
                                  <a className="delete-button" ><span className="img-box"><img src={archiveicon} /></span>Archive</a>
                                  <a className="delete-button" onClick={() => assetdelete(data.id)}><span className="img-box"><img src={deleteicon} /></span> Delete</a>
                                </div>
                              </div> */}
                  </div>

                );
              })}

            </div>
          </div>

        </div>

      </div>

      {/* <div className='m-3 '>
            <button className=' default-odd'  onClick={() => closepartition()}>Cancel</button>
            <button className=' default-even mx-3' onClick={() => handleDoneClick()}>Done</button>


            </div> */}
    </div>
  );
}

export default SearchView;
