import React from "react";
import "./Drawer.scss";
import ContactUs from "../../pages/contact";

export default function Drawer({ isOpen, onClose }) {
  return (
    <div className={`drawer ${isOpen ? "open" : ""}`} style={{backgroundColor:'#444648'}}>
             {isOpen && <div className="overlay" onClick={onClose}></div>}
      <div className="drawer-content">
        <button className="close-btn" onClick={onClose}>×</button>
        {/* Render the ContactUs component here */}
        <ContactUs />
      </div>
    </div>
  );
}
