


import React, { useRef, useState, useEffect } from "react";
import { Modal, Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
// import { AssetPoster_URL } from "../../service/API_URL";
// import instance from "../../service/axiosConfig";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import FilesDragAndDrop from "../../File-Uploader/FileUploader";
import { allAvailableChannelList } from "../../../api/api";
import "./index.scss";
import { StoreFrontChannelListAction } from "../../../Redux/slices";
import { createBanner, updateBanner } from "../../../api/api";
import { image_url } from "../../../service/Constant";
import deleteicon from "../../../assets/images/deleteicon.png";

function BannerPopup({ show, handleClose, popupType, selectedData, platforms = {} }) {
  const dispatch = useDispatch();
  const { channelList } = useSelector((state) => state.StoreFrontReducer);
  const [validationErrors, setValidationErrors] = useState({});
  const [activeTab, setActiveTab] = useState("DEFAULT");
  // const [startDate, setStartDate] = useState(selectedData?.startDate || "");
  // const [endDate, setEndDate] = useState(selectedData?.endDate || "");
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    bannerType: selectedData?.bannerType || "PLATFORM_BANNER",
    channelId: selectedData?.channel?.id || "",
    startDate: "",
    endDate: "",
    bannerImages: [],
    ...selectedData,
    weightage: selectedData?.weightage || "",
    redirectionUrl: selectedData?.redirectionUrl || "",
    bannerSize: selectedData?.bannerSize || "LARGE",
    ...selectedData,

  });
  const [size, setSize] = useState(null);

  const validateForm = () => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = "Banner name is required.";
    }

    if (!formData.startDate) {
      errors.startDate = "Start Date is required.";
    }

    if (!formData.endDate) {
      errors.endDate = "End Date is required.";
    }

    if (formData.bannerType === "AD_BANNER") {
      if (!formData.redirectionUrl.trim()) {
        errors.redirectionUrl = "Redirection URL is required.";
      }

      if (!formData.weightage || formData.weightage === "" || formData.weightage < 0 || formData.weightage > 100) {
        errors.weightage = "Weightage must be between 0 and 100";
      }

    }

    if (formData.bannerType === "AD_BANNER" && !uploadedFiles[activeTab.toUpperCase()]) {
      errors.bannerImage = "Banner image is required.";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };


  const initializeForm = () => {
    if (selectedData) {
      const startDate = selectedData.startDate ? new Date(selectedData.startDate) : "";
      const endDate = selectedData.endDate ? new Date(selectedData.endDate) : "";

      setFormData({
        ...formData,
        startDate,
        endDate,
      });
    }
  };


  useEffect(() => {
    getChannelList();
    initializeForm();
  }, [selectedData]);

  useEffect(() => {
    let startDate = null,
      endDate = null;
    if (typeof selectedData?.startDate == "string") {
      startDate =
        selectedData?.startDate && Date.parse(selectedData?.startDate);
    } else {
      startDate = selectedData?.startDate || "";
    }
    if (typeof selectedData?.endDate == "string") {
      endDate = selectedData?.endDate && Date.parse(selectedData?.endDate);
    } else {
      endDate = selectedData?.endDate || "";
    }
    setFormData({
      ...formData,
      startDate,
      endDate,
    });
    if (selectedData) {
      const image = selectedData?.images?.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.deviceType]: {
              ["fileData"]: `${image_url}${item.imagePath}`,
              ["deviceType"]: `${item.deviceType}`,
            },
          }),
        {}
      );
      setUploadedFiles({ ...image });

    }
  }, [selectedData]);

  const getChannelList = async () => {
    const result = await allAvailableChannelList();
    if (result?.data?.length) {
      dispatch(StoreFrontChannelListAction(result.data));
    }
  };

  const handleChannelChange = (event) => {
    setFormData({ ...formData, channelId: event.target.value });
  };

  const handleNameChange = (event) => {
    setFormData({ ...formData, name: event.target.value });
  };

  const delete_img = (data) => {
    setUploadedFiles((prevFiles) => {
      if (typeof prevFiles === 'object' && !Array.isArray(prevFiles)) {
        // Create a copy of the previous files object
        const updatedFiles = { ...prevFiles };
        // Delete the file from the object
        delete updatedFiles[data.deviceType];
        return updatedFiles;
      } else {
        console.error('prevFiles is not an object');
        return prevFiles;
      }
    });
  }

  const handleUpload = async () => {

    const isValid = validateForm();
    if (!isValid) return;


    const payload = new FormData();
    const keys = Object.keys(uploadedFiles);

    for (let i = 0; i < keys.length; i++) {
      if (uploadedFiles[keys[i]] && uploadedFiles[keys[i]].file) {
        payload.append(`bannerImages[${i}].deviceType`, uploadedFiles[keys[i]].deviceType);
        payload.append(`bannerImages[${i}].file`, uploadedFiles[keys[i]].file);
      } else if (!(uploadedFiles[keys[i]] && uploadedFiles[keys[i]].file)) {
        payload.append(`bannerImages[${i}].deviceType`, uploadedFiles[keys[i]].deviceType);
        const fileDataUrl = new URL(uploadedFiles[keys[i]].fileData);
        let pathname = fileDataUrl.pathname;
        if (pathname.startsWith('/')) {
          pathname = pathname.substring(1);
        }
        payload.append(`bannerImages[${i}].path`, pathname);
      }
    }
    payload.append("name", formData.name);
    payload.append("bannerType", formData.bannerType);
    if (formData.bannerType == "CHANNEL_BANNER") {
      payload.append("channelId", formData.channelId);
    } else {
      payload.append("channelId", "");
    }
    payload.append("startDate", formData.startDate);
    payload.append("endDate", formData.endDate);
    if (formData.bannerType === "AD_BANNER") {
      payload.append("redirectionUrl", formData.redirectionUrl);
      payload.append("bannerSize", formData.bannerSize);
      payload.append("weightage", formData.weightage);
    }
    let result = "";
    if (popupType === "Create") {
      result = await createBanner(payload);
    } else if (popupType === "Edit") {
      result = await updateBanner(payload, selectedData?.id);
    }
    if (result?.status == 200) {
      toast.success(result.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      handleClose(true);
    } else {
      toast.error(result?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      handleClose();
    }


  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const changeHandler = (event) => {
    const imageFile = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setUploadedFiles({
        ...uploadedFiles,
        [activeTab.toUpperCase()]: {
          file: event.target.files[0],
          fileData: reader.result,
          deviceType: activeTab?.toUpperCase(),
        },
      });
    };
    reader.readAsDataURL(imageFile);
  };

  const updateDate = (type, date) => {
    if (type == "start") {
      // setStartDate(new Date(date));
      setFormData({
        ...formData,
        startDate: (date && Date.parse(date)) || null,
      });
    }
    if (type == "end") {
      // setEndDate(new Date(date));
      setFormData({ ...formData, endDate: (date && Date.parse(date)) || null });
    }
  };

  const bannerTypeChange = (e) => {
    setFormData({ ...formData, bannerType: e.target.value });
  };

  return (

    <div className="bannerpopup" >
      <Modal show={show} onHide={handleClose}>
        <div closeButton>
          <Modal.Title style={{ paddingLeft: 15 }}>{popupType}  Banner</Modal.Title>
        </div>
        <Modal.Body>
          <Form.Group>
            <Form.Label className="Lables">Name</Form.Label>
            <Form.Control
              placeholder="Enter Banner name"
              type="text"
              onChange={handleNameChange}
              value={formData.name}

            />
            {validationErrors.name && (
              <div className="text-danger">{validationErrors.name}</div>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label style={{ display: "block" }} className="Lables">Banner Type</Form.Label>
            <label class="checkbox-container">
              <input
                type="radio"
                name="bannertype"
                value="PLATFORM_BANNER"
                checked={formData.bannerType == "PLATFORM_BANNER"}
                onChange={bannerTypeChange}

              />
              {validationErrors.bannerType && (
                <div className="text-danger">{validationErrors.bannerType}</div>
              )}
              <span style={{ marginLeft: "10px" }} className="TypeBanner">Platform Banner</span>
            </label>
            <label class="checkbox-container" style={{ marginLeft: "10px" }}>
              <input
                type="radio"
                name="bannertype"
                value="CHANNEL_BANNER"
                checked={formData.bannerType == "CHANNEL_BANNER"}
                onChange={bannerTypeChange}
              />
              <span style={{ marginLeft: "10px" }} className="TypeBanner">Channel Banner</span>
            </label>
            <label className="checkbox-container" style={{ marginLeft: "10px" }}>
              <input
                type="radio"
                name="bannertype"
                value="AD_BANNER"
                checked={formData.bannerType === "AD_BANNER"}
                onChange={bannerTypeChange}
              />
              <span style={{ marginLeft: "10px" }} className="TypeBanner">Ad Banner</span>
            </label>
          </Form.Group>
          {formData.bannerType == "CHANNEL_BANNER" ? (
            <Form.Group>
              <Form.Label className="Lables">Select Channel</Form.Label>
              <select
                className="form-select"
                onChange={handleChannelChange}
                value={formData?.channelId}
              >
                <option value="">Select Channel</option>
                {channelList?.map((item) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </Form.Group>
          ) : null}
          {formData.bannerType === "AD_BANNER" && (
            <>
              <Form.Group>
                <Form.Label className="Lables">
                  Redirection URL <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  placeholder="Enter Redirection URL"
                  type="text"
                  onChange={(e) => setFormData({ ...formData, redirectionUrl: e.target.value })}
                  value={formData.redirectionUrl}
                />
                {validationErrors.redirectionUrl && (
                  <div className="text-danger">{validationErrors.redirectionUrl}</div>
                )}
              </Form.Group>

              {/* <Form.Group>
                <Form.Label className="Lables">Banner Size</Form.Label>
                <select
                  title={formData.bannerSize || "Select Banner Size"}
                  onSelect={(size) => setFormData({ ...formData, bannerSize: size })}
                  id="dropdown-banner-size"
                  variant="outline-secondary"
                  style={{
                    width: "100%",
                    padding: "15px",
                    fontSize: "14px",
                    borderRadius: "5px",
                    backgroundColor: "#2e3033",
                    color: '#fff',

                  }}

                >
                  <option eventKey="LARGE">Large</option>
                  <option eventKey="MEDIUM">Medium</option>
                  <option eventKey="SMALL">Small</option>
                </select>
              </Form.Group> */}

<Form.Group>
  <Form.Label className="Lables">Banner Size</Form.Label>
  <div style={{ display: "flex", gap: "15px"}}>
    <div>
      <input
        type="radio"
        id="large"
        name="bannerSize"
        value="LARGE"
        checked={formData.bannerSize === "LARGE"}
        onChange={(e) => setFormData({ ...formData, bannerSize: e.target.value })}
        style={{
          marginRight: "5px",
          backgroundColor: "#2e3033",
          color: '#fff',
        }}
      />
      <label htmlFor="large" style={{ color: '#fff', paddingLeft:5 ,fontWeight:400}}>Large</label>
    </div>
    <div>
      <input
        type="radio"
        id="medium"
        name="bannerSize"
        value="MEDIUM"
        checked={formData.bannerSize === "MEDIUM"}
        onChange={(e) => setFormData({ ...formData, bannerSize: e.target.value })}
        style={{
          marginRight: "5px",
          backgroundColor: "#2e3033",
          color: '#fff',
        }}
      />
      <label htmlFor="medium" style={{ color: '#fff', paddingLeft:5  ,fontWeight:400}}>Medium</label>
    </div>
    <div>
      <input
        type="radio"
        id="small"
        name="bannerSize"
        value="SMALL"
        checked={formData.bannerSize === "SMALL"}
        onChange={(e) => setFormData({ ...formData, bannerSize: e.target.value })}
        style={{
          marginRight: "5px",
          backgroundColor: "#2e3033",
          color: '#fff',
        }}
      />
      <label htmlFor="small" style={{ color: '#fff', paddingLeft:5,fontWeight:400}}>Small</label>
    </div>
  </div>
</Form.Group>


              <Form.Group>
                <Form.Label className="Lables">
                  Banner Weightage <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  min="0"
                  max="100"
                  value={formData.weightage}
                  onChange={(e) => {
                    setFormData({ ...formData, weightage: e.target.value });
                    // Optionally validate here as well when the value changes
                    validateForm();
                  }}
                  placeholder="Enter Banner Weightage (0-100%)"
                />
                {validationErrors.weightage && (
                  <div className="text-danger">{validationErrors.weightage}</div>
                )}
                <Form.Text className="text-muted">
                  Please enter a value between 0 and 100.
                </Form.Text>
              </Form.Group>


            </>
          )}

          <div className="bannerDates">
            <Form.Group style={{marginTop:-15}}>
              <Form.Label className="Lables">
                Start Date <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <DatePicker
                  className="startDatepicker"
                  selected={formData.startDate || null}
                  onChange={(date) => updateDate("start", date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  disabled={popupType == "View"}
                // isClearable
                />
              </div>
            </Form.Group>
            <Form.Group style={{ marginLeft: 30 ,marginTop:-15}}>
              <Form.Label className="Lables">
                End Date <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <DatePicker
                  className="endDatepicker"
                  selected={formData?.endDate || null}
                  onChange={(date) => updateDate("end", date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/mm/yyyy"
                  // isClearable
                  minDate={formData?.startDate}
                  disabled={!formData?.startDate || popupType == "View"}
                />
              </div>
            </Form.Group>
          </div>

          <div className="mt-2">
            <div className="mt-2" style={{marginBottom:'-15px'}}>
              <label className="form-label Lables">Images</label>
              <div className="image-button">
                {/* {["Default", "Mobile", "Tablet", "TV", "Web"].map((tab) => ( */}
                {platforms.map((tab) => (
                  <span
                    key={tab}
                    className={activeTab === tab ? "active" : ""}
                    style={{backgroundColor:'#8E9192'}}
                    onClick={() => handleTabChange(tab)}
                  >
                    {tab}
                  </span>
                ))}
              </div>
            </div>
            <div className="mt-30 box-shadow d-flex">
              {!uploadedFiles[activeTab.toUpperCase()]?.fileData ? (
                <FilesDragAndDrop
                  id="Mobile-poster"
                  imgcategory="poster"
                  label={`${activeTab} Banner`}
                  index={1}
                  // size={size}
                  // uploadedFiles={uploadedFiles}
                  onChangeHandler={changeHandler}
                  // deleteimages={delete_poster}
                  imagetype={size}
                // edit={onlyview}
                />
              ) : (
                <>
                  <img
                    style={{
                      height: "80px",
                      width: "110px",
                      // marginTop: "5px",
                      marginLeft: "10px",
                      borderRadius: "5px",
                     
                    }}
                    src={
                      uploadedFiles[activeTab.toUpperCase()]?.fileData
                      // `${image_url}${images?.[activeTab.toUpperCase()]}`
                    }
                  />
                  <a>
                    <img
                      onClick={() => delete_img(uploadedFiles[activeTab.toUpperCase()])}
                      src={deleteicon}
                      alt="Delete"
                    />
                  </a>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{marginTop:'-5px'}}>
          <Button variant="secondary" style={{ backgroundColor: '#FAEAE9' }} onClick={handleClose}>
            Close
          </Button>
          {popupType != "View" ? (
            <Button variant="secondary" onClick={handleUpload} style={{ backgroundColor: '#D22F26', color: '#fff', marginLeft: 20 }}>
              {popupType == "Edit" ? "Update" : "Create"}
            </Button>
          ) : null}
        </Modal.Footer>
      </Modal>
    </div>

  );
}

export default BannerPopup;