

import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Height, Style } from '@material-ui/icons';

// Registering necessary chart components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const CustomLineChart = ({ selectedChannelList }) => {
  const colors = [
    { border: '#F39F53', background: 'rgba(255, 99, 132, 0.2)' },
    { border: '#FFEE50', background: 'rgba(54, 162, 235, 0.2)' },
    { border: '#73DB32', background: 'rgba(75, 192, 192, 0.2)' },
    { border: '#AD55F3', background: 'rgba(153, 102, 255, 0.2)' },
    { border: '#1BE3E4', background: 'rgba(255, 159, 64, 0.2)' },
  ];

  const newDataSets = selectedChannelList?.map((channel, index) => ({
    label: channel,
    data: [
      0, 60, 120, 180, 240, 300, 360, 420, 480, 540, 600, 540,
      480, 420, 360, 300, 240, 180, 120, 60, 30, 20, 10, 0,
    ],
    borderColor: colors[index % colors.length].border, 
    backgroundColor: colors[index % colors.length].background, 
    borderWidth: 4, 
    pointStyle: 'circle', 
    pointRadius: 4, 
    // pointBackgroundColor: colors[index % colors.length].border, 
    pointBackgroundColor: '#000', 
    pointBorderColor: '#FFFFFF', 
    pointBorderWidth: 2, 
  }));
  
 
  const data = {
    labels: [
      '12 am', '1 am', '2 am', '3 am', '4 am', '5 am', '6 am', '7 am', '8 am', '9 am', '10 am', '11 AM',
      '12 pm', '1 pm', '2 pm', '3 pm', '4 pm', '5 pm', '6 pm', '7 pm', '8 pm', '9 pm', '10 pm', '11 pm'
    ],
    datasets: newDataSets,
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
      },
      legend: { display: false },
    },
    scales: {
      x: {
        title: {
          display: true,
        },
        ticks: {
          color: "#D9D9D9", // Y-axis labels color
          padding: 10, // Padding to move labels away
          font: {
            size: 14, // Font size
            weight: 400, // Font weight
          },
        },
        grid: {
          lineWidth: 30.23,
          color: "#2E3033", 
          borderDash: [5, 5], 
          drawOnChartArea: true,
        
        },
      },
      y: {
        title: {
          display: true,
          // text: 'Average Watch Time Per Session',
      },
      ticks: {
        color: "#D9D9D9",
        padding: 10, 
        font: {
          size: 14, // Font size
          weight: 400, // Font weight
        },
        
      },
        border:{dash: [4, 4]},
        grid: {
          lineWidth: 0.91,
          color: "#C9CBCD",
  
        tickBorderDash: [4, 4]
        },
      },
    },
    layout: {
      
      backgroundColor: '#1A1A1A', 
      padding: {
        top: 0,
        left: 0,
        right: 30,  // Add padding to the right end here
        bottom: 0,
      },

    },
    
  };

  return (<div
    style={{
      backgroundColor: '#1A1A1A',

      height: '500px', 
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', 
 
    }}
  >

    <Line data={data} options={options} style={{width:'100%'}}/>
  </div>) 
  
  // <Line data={data} options={options} />;
};

export default CustomLineChart;

