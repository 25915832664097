
import React, { useEffect, useState, useRef } from "react";
import AccordionBlock from "./../../accordian/AccordionBlock";
import './Graph.scss';
import { useDispatch, useSelector } from "react-redux";
import { ViewershipTablehead, ViewershipMap } from "../../../../api/api";
import { capitalizeFirstLetter, formatNumber, isLogin, TimeConverter } from "../../../../service/commonFunc";
import sort from "../../../../assets/images/sort.png";
import desc from "../../../../assets/images/desc.png";
import filter_alt from "../../../../assets/images/sortarrow.png";
import { ChannelDataAction, MapDataAction } from "../../../../Redux/slices";
import AccordionFilter from "../../accordian/AccordionFilter";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import GraphAccordionFilter from "../../accordian/GraphAccordionFilter";
import axios from "axios";
import CustomLineChart from "../../../../component/CustomLineChart";

function GraphView() {
  const [TableHead, setTableHead] = useState([]);

  const [openIndex, setOpenIndex] = useState(null);
  const [showLoader, setshowLoader] = useState(true);
  const [Duration, setDuration] = useState("Last 24 Hours");
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedChannelList, setSelectedChannelList] = useState([]);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Number of Active Users",
        data: [],
        fill: false,
        borderColor: "#1BE3E4",
        tension: 0.2,
        borderWidth: 4,
        pointRadius: 4,
        pointBackgroundColor: "#0f520c",
        pointBorderColor: "#ffffff",
        pointBorderWidth: 2,
        pointHoverRadius: 6,
        hidden: false,
      },
      {
        label: "Total Watch Hours",
        data: [],
        fill: false,
        tension: 0.2,
        borderColor: "#FFFF00",
        borderWidth: 4,
        pointRadius: 4,
        pointBackgroundColor: "#000000",
        pointBorderColor: "#ffffff",
        pointBorderWidth: 2,
        pointHoverRadius: 6,
        hidden: false,
      },
      {
        label: "Total Ad Impression",
        data: [],
        fill: false,
        borderColor: "#A52A2A",
        tension: 0.2,
        borderWidth: 4,
        pointRadius: 4,
        pointBackgroundColor: "#000000",
        pointBorderColor: "#ffffff",
        pointBorderWidth: 2,
        pointHoverRadius: 6,
        hidden: false,
      },
    ],
  });
  const fetchGraphData = async () => {
    try {
      const channelDataPromises = selectedChannels.map((channelId) =>
        axios.get(`/api/channel-data`, { params: { id: channelId } })
      );
      const responses = await Promise.all(channelDataPromises);
      const newDatasets = responses.map((res, index) => ({
        label: res.data.name,
        data: res.data.data,
        borderColor: getColor(index), // Helper to generate colors dynamically
        borderWidth: 2,
      }));
      setChartData({ labels: responses[0].data.labels, datasets: newDatasets });
    } catch (error) {
      console.error("Error fetching graph data:", error);
    }
  };

  useEffect(() => {
    if (selectedChannels.length) fetchGraphData();
  }, [selectedChannels]);

  const getColor = (index) => {
    const colors = ["#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#A1FF33"];
    return colors[index % colors.length];
  };

  const [chartOptions, setChartOptions] = useState({});
  const [graphicalView, setGraphicalView] = useState(false);
  const [channelData, setChannelData] = useState([]);
  const [filterVal, setFilterVal] = useState({
    viewType: "CHANNEL",
    duration: "DAY",
  });
  const [sortOrders, setSortOrders] = useState({ viewKey: "asc" });
  const [activeView, setActiveView] = useState('activeUser');
  const [TotalHeader, setTotalHeader] = useState({
    viewType: "HEADER",
    viewKey: "Average",
    numberOfActiveUsers: 0,
    totalWatchHours: 0,
    averageWatchTimePerUser: 0,
    averageWatchTimePerSession: 0,
    totalAdImpression: 0,
  });

  const {
    appThemeReducer: { appTheme },
    adminModeReducer: { adminMode },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const chartRef = useRef(null);

  const sortOrderEvent = (columnValue) => {
    const currentSortOrder = sortOrders[columnValue] || "asc";
    const newSortOrder = currentSortOrder === "desc" ? "asc" : "desc";

    setSortOrders({ [columnValue]: newSortOrder });

    const headeFilter = {
      sort: newSortOrder,
      key: columnValue,
    };
    const mapRenderData = async (key) => {
      const ViewershipMapData = {
        channelName: key,
        allChannels: true
      };

      const MapData = await ViewershipMap(ViewershipMapData);

      if (MapData?.data) {
        dispatch(MapDataAction(MapData?.data));
      }
    };


    filterChangeData(filterVal.viewType, filterVal.duration, headeFilter);
  };

  const filterChangeData = async (view, duration, headeFilter) => {
    const ViewershipTableheadData = {
      viewType: view.toUpperCase(),
      duration: duration.toUpperCase(),
      sortOrder: headeFilter.sort,
      sortBy: headeFilter.key,
    };

    const data = await ViewershipTablehead(ViewershipTableheadData, setshowLoader);
    console.log("datadatadatadatarrraj", JSON.stringify(data))
    setChannelData(data?.data?.data?.rows)
    if (duration === "Month") {
      setDuration("Last 30 Days");
    } else if (duration === "Week") {
      setDuration("Last 7 Days");
    } else if (duration === "Year") {
      setDuration("Last 365 Days");
    } else {
      setDuration("Last 24 Hours");
    }

    setTableHead(data?.data?.data?.rows);
    // mapRenderData(data?.data.data?.header.viewKey);
    setTotalHeader(data?.data.data?.header);

    if (filterVal.duration === duration.toUpperCase()) {
      setOpenIndex(null);
      dispatch(ChannelDataAction(data?.data.data?.header));
    }


    const xdata = [];
    const ydata = [];
    const ydata2 = [];
    const ydata3 = [];

    data?.data?.data?.rows.forEach((item) => {
      xdata.push(item.viewKey);
      ydata.push(item.numberOfActiveUsers);
      ydata2.push(item.totalWatchHours);
      ydata3.push(item.totalAdImpression);
    });

    setChartData((prev) => ({
      ...prev,
      labels: xdata,
      datasets: [
        { ...prev.datasets[0], data: ydata },
        { ...prev.datasets[1], data: ydata2 },
        { ...prev.datasets[2], data: ydata3 },
      ],
    }));
  };

  useEffect(() => {
    isLogin();
    filterChangeData(filterVal.viewType, filterVal.duration, { sort: "asc", key: "viewKey" });
  }, [filterVal]);

  useEffect(() => {
    setChartOptions({
      plugins: {
        tooltip: {
          enabled: true,
          mode: "index",
          intersect: false,
        },
        legend: { display: true },
      },
      scales: {
        x: {
          display: true,
          title: { display: true, text: "Program Name" },
          grid: {
            lineWidth: 20,
            color: appTheme === "dark" ? "#2E3033" : "#FCFCFD",

          },
        },
        y: {
          display: true,
          title: { display: true, text: "Information" },
          beginAtZero: true,
          ticks: { padding: 20 },
          grid: {
            lineWidth: 1, // You can adjust this width as needed
            color: appTheme === "dark" ? "#C9CBCD" : "#FCFCFD",
            borderDash: [15, 20], // Adds a dashed effect with longer dashes and space between
          },
        },
      },
      maintainAspectRatio: false,
    });
  }, [appTheme]);

  const toggleActiveView = (view) => {
    setActiveView(view);
    filterChangeData(filterVal.viewType, filterVal.duration, {
      sort: "asc",
      key: view === "activeUser" ? "numberOfActiveUsers" : "averageWatchTimePerSession",
    });
  };

  useEffect(() => {
    isLogin();
    filterChangeData(filterVal.viewType, filterVal.duration, { sort: "asc", key: "viewKey" });
  }, [filterVal]);

  return (
    <>

      <div className="dashboard-content" >
        <GraphAccordionFilter setSortOrders={setSortOrders} Durations={Duration} handleChange={filterChangeData} channelData={channelData}
          handleChannelChange={(data) => {
            console.log("data", data)
            setSelectedChannelList(data)
          }}
        />
      </div>
      <div >



        <div className="channel-container" style={{ backgroundColor: "#1a1a1a", marginTop: 33 }}>
          <div className="channel-table" style={{ width: '100%', backgroundColor: '#2E3033', padding: 20, borderRadius: 10 }}>
            <div className="channel-table-header" style={{ backgroundColor: 'transparent' }}>
              <div className="custom-button-container">

                <div className="custom-button-container">

                  <button
                    className={`custom-btn ${activeView === 'activeSession' ? 'active-btn' : ''}`}
                    onClick={() => toggleActiveView('activeSession')}
                  >
                    Active Sessions
                  </button>
                  <button
                    className={`custom-btn ${activeView === 'activeUser' ? 'active-btn' : ''}`}
                    onClick={() => toggleActiveView('activeUser')}
                  >
                    Active users
                  </button>

                </div>


              </div>

              <div className="table-header-container" style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginTop: 20, }}>
                {/* Total Active Users */}
                <div
                  className="table-header-box"
                  style={{
                    flex: "1 1 200px",
                    textAlign: "center",
                    background: "#1A1A1A",
                    padding: "10px",
                    borderLeft: "3px solid #D22F26B2",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    className="header-content"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div className="header-title" style={{ textAlign: "left", color: "#FFF", width: 100 }}>Total Active Users</div>
                    <div className="header-value" style={{ color: "#FFF" }}>
                      {TotalHeader?.numberOfActiveUsers !== undefined ? formatNumber(TotalHeader?.numberOfActiveUsers) : ""}
                    </div>
                  </div>
                  <a className="filter-icon" onClick={() => sortOrderEvent("numberOfActiveUsers")}></a>
                </div>

                {/* Total Watch Hours */}
                <div
                  className="table-header-box"
                  style={{
                    flex: "1 1 200px",
                    textAlign: "center",
                    background: "#1A1A1A",
                    padding: "10px",
                    borderLeft: "3px solid #D22F26B2",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    className="header-content"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div className="header-title" style={{ textAlign: "left", color: "#FFF", width: 100 }}>Total Watch Hours</div>
                    <div className="header-value" style={{ color: "#FFF" }}>
                      {TotalHeader?.totalWatchHours !== undefined ? formatNumber(TotalHeader?.totalWatchHours?.toFixed(2)) + " Hrs" : ""}
                    </div>
                  </div>
                  <a className="filter-icon" onClick={() => sortOrderEvent("totalWatchHours")}></a>
                </div>

                {/* Avg - Watch Time Per User */}
                <div
                  className="table-header-box"
                  style={{
                    flex: "1 1 200px",
                    textAlign: "center",
                    background: "#1A1A1A",
                    padding: "10px",
                    borderLeft: "3px solid #D22F26B2",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    className="header-content"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div className="header-title" style={{ textAlign: "left", color: "#FFF", width: 130 }}>Avg - Watch Time Per User</div>
                    <div className="header-value" style={{ color: "#FFF" }}>
                      {TotalHeader?.averageWatchTimePerUser !== undefined ? TimeConverter(TotalHeader?.averageWatchTimePerUser) : ""}
                    </div>
                  </div>
                  <a className="filter-icon" onClick={() => sortOrderEvent("averageWatchTimePerUser")}></a>
                </div>

                {/* Avg - Watch Time Per Session */}
                <div
                  className="table-header-box"
                  style={{
                    flex: "1 1 200px",
                    textAlign: "center",
                    background: "#1A1A1A",
                    padding: "10px",
                    borderLeft: "3px solid #D22F26B2",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    className="header-content"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div className="header-title" style={{ textAlign: "left", color: "#FFF", width: 130 }}>Avg - Watch Time Per Session</div>
                    <div className="header-value" style={{ color: "#FFF" }}>
                      {TotalHeader?.averageWatchTimePerSession !== undefined ? TimeConverter(TotalHeader?.averageWatchTimePerSession) : ""}
                    </div>
                  </div>
                  <a className="filter-icon" onClick={() => sortOrderEvent("averageWatchTimePerSession")}></a>
                </div>
              </div>



            </div>

            <div className="line-graph" style={{ height: '80%', backgroundColor: '#1a1a1a',width:'100%' }}>
              {/* <Line ref={chartRef} data={chartData} options={chartOptions}  /> */}
              <CustomLineChart selectedChannelList={selectedChannelList} />
            </div>
          </div>
        </div>


      </div>





    </>
  );
}

export default GraphView;
