import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "./schedulingLoopNew.scss";
import { useSelector } from "react-redux";
import { 
    Schedules_list,
    Schedules_Datalist_Loop, 
    ScheduleLoop_update,
    programValidate,
    adValidate,
} from "../../../api/api";
import revertIcon from "../../../assets/images/revert.svg";
import icroundsearch from "../../../assets/images/ic_round-search.svg";
import { toast, ToastContainer } from "react-toastify";
import zoomInIcon from "../../../assets/images/zoom-in.svg";
import zoomOutIcon from "../../../assets/images/zoom-out.svg";
import deleteIcon from '../../../assets/images/delete_forever.png';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import adMarkers from "../../../assets/images/adMarkers.svg";
import editIcon from '../../../assets/images/edit-outline.svg';
import ellipse from "../../../assets/images/Ellipse.png";

function SchedulingLoopNew() {
    const locationState = useLocation();
    const [programList, setProgramList] = useState();
    const [originalProgramList, setOriginalProgramList] = useState([]);
    const [promoList, setPromoList] = useState();
    const [fillerList, setFillerist] = useState();
    const [gridList, setGridList] = useState();    
    console.log('gridList',gridList);
    const [totalDuration, setTotalDuration] = useState({
        duration: "00:00:00",
        totalNum: 0,
    });
    const [totalVideoDuration, setTotalVideoDuration] = useState({
        duration: "00:00:00",
        totalNum: 0,
    });
    const [totalAdDuration, setTotalAdDuration] = useState({
        duration: "00:00:00",
        totalNum: 0,
    });
    const [totalPromosDuration, setTotalPromosDuration] = useState({
        duration: "00:00:00",
        totalNum: 0,
    });
    const [selectedTab, setSelectedTab] = useState("Programs");
    const [zoomInOut, setZoomInOut] = useState(10);
    const [assetInfo, setAssetInfo] = useState(null);
    const [categoryTab, setCategoryTab] = useState("Ads");
    const [seqId, setSeqId] = useState(1);
    const [hoveredId, setHoveredId] = useState(null);    
    const [linePosition, setLinePosition] = useState(null);
    const [hoverTime, setHoverTime] = useState(null);
    const [timeSlots, setTimeSlots] = useState([]);
    const [singleTimeSlots, setSingleTimeSlots] = useState([]);
    const [searchedText, setSearchedText] = useState("");
    const draggedItem = useRef();    
    const {
        appThemeReducer: { appTheme },
    } = useSelector((state) => state);

    const toMilliseconds = (hrs,min,sec) => (parseInt(hrs)*60*60+parseInt(min)*60+parseInt(sec))*1000;
    const verifyMilliSeconds = (duration) => {
        const len = duration?.toString()?.split(":");
        if(len?.length > 1) {
          return parseInt(toMilliseconds(len[0], len[1], len[2]))
        } else {
          return parseInt(duration);
        }
    }

    const millisecondsToHuman = (duration) => {
        // if(duration === 0) return '00:00:00';
        let milliseconds = parseInt((duration % 1000) / 100),
        seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)));

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;
        seconds = (seconds < 10) ? "0" + seconds : seconds;

        return hours + ":" + minutes + ":" + seconds ;
    }

    const totalScheduleTimeFun = () => {
        let totalDuration = 0, videoDuration = 0, adsDuration = 0, promosDuration = 0, fillerDuration = 0;
        let totalVideos = 0, totalAds = 0, totalPromos = 0, totalFillers = 0;
        gridList?.forEach((data => {
            if(!data?.totalDurationInMillis) {
                data.totalDurationInMillis = data.durationInMillis;
            }
            totalDuration += verifyMilliSeconds(data?.totalDurationInMillis);
            videoDuration += verifyMilliSeconds(data?.durationInMillis);
            totalVideos += 1;

            data.adBreaks.forEach((adData) => {
                switch(adData.assetType?.toLowerCase()) {
                  case "filler":
                    fillerDuration += verifyMilliSeconds(adData?.durationInMillis);
                    totalFillers += 1;
                    break;
                  case "promo":
                    promosDuration += verifyMilliSeconds(adData?.durationInMillis);
                    totalPromos += 1;
                    break;
                  default:
                    adsDuration += verifyMilliSeconds(adData?.durationInMillis);
                    totalAds += 1;
                    break;
                }
            })
        }))

        // Handle time slot repetition based on video duration
        const hoursInADay = 24;
        const millisInAnHour = 60 * 60 * 1000;
        const millisInADay = hoursInADay * millisInAnHour;

        const fullDays = Math.floor((videoDuration + fillerDuration + adsDuration) / millisInADay);
        const remainingDurationMillis = (videoDuration + + fillerDuration + adsDuration) % millisInADay;

        const repeatedSlots = [];
        // Repeat for full days
        for (let i = 0; i < fullDays; i++) {
            repeatedSlots.push(...singleTimeSlots);
        }
        // Add remaining slots
        const remainingHours = Math.ceil(remainingDurationMillis / millisInAnHour);
        if (remainingHours > 0) {
            repeatedSlots.push(...singleTimeSlots.slice(0, Math.min(remainingHours * 2, singleTimeSlots.length)));
        }
        // Set time slots based on video duration
        setTimeSlots(repeatedSlots);
        
        setTotalDuration({
            duration: millisecondsToHuman(totalDuration),
            totalNum: totalVideos + totalFillers + totalPromos + totalAds,
        });
        setTotalVideoDuration({
            duration: millisecondsToHuman(videoDuration),
            totalNum: totalVideos,
        });
        setTotalAdDuration({
            duration: millisecondsToHuman(adsDuration + fillerDuration),
            totalNum: totalAds + totalFillers,
        });
        setTotalPromosDuration({
            duration: millisecondsToHuman(promosDuration),
            totalNum: totalPromos,
        });
        
    }

    const fetchSchedule = async () => {
        if(!locationState?.state?.channelId) {
            return;
        }
        const scheduleData = await Schedules_list(locationState?.state?.channelId, 'PROGRAM');
        setProgramList(scheduleData?.data);
        setOriginalProgramList(scheduleData?.data);

        const fillerData = await Schedules_list(locationState?.state?.channelId, 'FILLER');
        setFillerist(fillerData?.data);

        const promoData = await Schedules_list(locationState?.state?.channelId, 'PROMO');
        setPromoList(promoData?.data);

        const gridData = await Schedules_Datalist_Loop(locationState?.state?.channelId);
        addadsSqId(gridData?.data)
        setAssetInfo(gridData?.data[0]);
    }

    const update_schedule = async () => {
        const response = await ScheduleLoop_update(gridList, locationState?.state?.channelId);
        if (response) {
            toast.success("Channel Updated successfully", {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000
            });
            fetchSchedule();
        } else {
            toast.error("Channel Update failed", {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000
            });
        }
    }

    // const addadsSqId = (data) => {
    //     let startTime = 0;
    //     // Sort the programs based on startOffsetInMillis
    //     const sortedData = data?.sort((a, b) => a.startOffsetInMillis - b.startOffsetInMillis);

    //     const updatedGridList = sortedData?.map((program) => {
    //         const endTime = startTime + program.durationInMillis;

    //         // Sort the adBreaks based on startOffsetInMillis (if needed) and reassign adSqId
    //         const updatedAdBreaks = program?.adBreaks
    //             ?.sort((a, b) => a.startOffsetInMillis - b.startOffsetInMillis) // Sort adBreaks
    //             .map((ad, index) => ({
    //                 ...ad,
    //                 adSqId: index + 1, // Reassign incremental adSqId after sorting
    //             }));

    //         const updatedProgram = {
    //             ...program,
    //             adBreaks: updatedAdBreaks,
    //             scheduleStartTime: startTime,
    //             scheduleEndTime: endTime,
    //         };

    //         startTime = endTime; // Update startTime for the next program

    //         return updatedProgram;
    //     });
        
    //     setGridList(updatedGridList); // This will overwrite the gridList with updated values
    // };
    
    const addadsSqId = (data) => {
        let startTime = 0;
    
        // Sort the programs based on startOffsetInMillis
        const sortedData = data?.sort((a, b) => a.startOffsetInMillis - b.startOffsetInMillis);
    
        const updatedGridList = sortedData?.map((program) => {
            // Calculate total duration from adBreaks
            const totalAdBreakDuration = program?.adBreaks?.reduce((sum, ad) => sum + ad.durationInMillis, 0) || 0;
    
            const endTime = startTime + program.durationInMillis + totalAdBreakDuration; // Add adBreaks duration
    
            // Sort the adBreaks based on startOffsetInMillis (if needed) and reassign adSqId
            const updatedAdBreaks = program?.adBreaks
                ?.sort((a, b) => a.startOffsetInMillis - b.startOffsetInMillis) // Sort adBreaks
                .map((ad, index) => ({
                    ...ad,
                    adSqId: index + 1, // Reassign incremental adSqId after sorting
                }));
    
            const updatedProgram = {
                ...program,
                adBreaks: updatedAdBreaks,
                scheduleStartTime: startTime,
                scheduleEndTime: endTime,
            };
    
            startTime = endTime; // Update startTime for the next program
    
            return updatedProgram;
        });
    
        setGridList(updatedGridList); // This will overwrite the gridList with updated values
    };
    
    useEffect(() => {
        totalScheduleTimeFun();
        getAssetInfo(seqId);
    },[gridList])
    
    useEffect(()=> {
        fetchSchedule();
    },[locationState])

    // Function to generate time slots from 6:00 AM to 5:30 AM
    const generateTimeSlots = () => {
        let timeSlotsArray = [];
        let currentHour = 6; // Start at 6:00 AM
        let isPM = false;
    
        while (!(currentHour === 5.5 && !isPM)) {
            let hours = Math.floor(currentHour) % 12 || 12; // Convert to 12-hour format, default to 12 for 0
            let minutes = (currentHour % 1 === 0) ? '00' : '30'; // Set minutes to 00 or 30
            let period = isPM ? 'PM' : 'AM';
    
            // Format the time correctly
            let time = `${hours}:${minutes} ${period}`;
            timeSlotsArray?.push(time);
    
            // Increment by 30 minutes
            currentHour += 0.5;
            
            // Switch AM/PM after 12 PM and wrap after midnight
            if (currentHour >= 12 && currentHour < 24) isPM = true;
            else if (currentHour >= 24) {
                isPM = false;
                currentHour -= 24; // Reset to continue into AM hours
            }
        }
        timeSlotsArray?.push("5:30 AM");
        return timeSlotsArray;
    };

    // Set the time slots in state when the component mounts
    useEffect(() => {
        const slots = generateTimeSlots();
        setTimeSlots(slots);
        setSingleTimeSlots(slots);
    }, []);

    const channelDragStart = (event) => {
        draggedItem.current = event.target;
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.setData("text/html", draggedItem.current.innerHTML);
    };

    const handleDragStart = (event) => {
        draggedItem.current = event.target;
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.setData("text/html", draggedItem.current.innerHTML);
    };

    const handleDragOver = (event, id) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    };

    const handleDragOver2 = (event, id) => {
        event.preventDefault();
        setHoveredId(id); // Set the currently hovered asset ID
        const containerTop = event.currentTarget.getBoundingClientRect().top;
        const yPos = event.clientY - containerTop;
        const durationPerPixel = 1/zoomInOut; // Adjust this as needed
        const timeAtPosition = yPos * durationPerPixel;

        // Convert time from minutes to hh:mm:ss format
        const totalSeconds = Math.floor(timeAtPosition * 60);
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

        setLinePosition(yPos);
        setHoverTime(formattedTime);
    };

    const addToDragabbleList = (data) => {
        let listSize = gridList.length;
        // const obj = { ...data, position: listSize + 1 };
        const obj = { ...data, sequence: listSize + 1 };
        setGridList([...gridList, obj]);
    };

    function getFormattedDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function generateUniqueUUID(gridList) {
        // Generate a UUID and ensure it is unique within gridList
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let uuid;
            do {
                const r = Math.random() * 16 | 0; 
                const v = c === 'x' ? r : (r & 0x3 | 0x8); 
                uuid = v.toString(16); 
            } while (gridList.some(item => item.uuid === uuid));
            return uuid;
        });
    }

    const handleDrop = async (event) => {
        event.preventDefault();
        setHoveredId(null);
        setLinePosition(null);
        // setHoverTime(null);
        let { type, value, key } = draggedItem.current.dataset;    
        let { id } = event.target.dataset;
        setSeqId(id);
        try {
            value = JSON.parse(value);
        } catch (error) {
            // If JSON.parse fails, leave value as-is (e.g., time string "00:00:30") for ads
        }
        // value = value ? JSON.parse(value) : value;
        let ads = [];
        let obj = {};
        if (type == "programs" && value) {

            const assetValidate = await programValidate(value["id"]);
            if (!assetValidate?.status) {
                toast.error(assetValidate?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 3000
                });
                return;
            }

            if (value?.defaultAdMarkers.length) {
                const updatedAdBreaks = value?.defaultAdMarkers
                ?.sort((a, b) => a.startOffsetInMillis - b.startOffsetInMillis) // Sort adBreaks
                .map((ad, index) => ({
                    ...ad,
                    adSqId: index + 1, // Reassign incremental adSqId after sorting
                }));
                ads = updatedAdBreaks;
            }
            const lastItem = gridList[gridList.length - 1];
            const uuid = generateUniqueUUID(gridList);
             
            obj = {
                assetId: value["id"],
                assetType: "PROGRAM",
                title: value["metadata"]["title"],
                durationInMillis: value["duration"],
                scheduleStartTime: lastItem?.scheduleEndTime? lastItem?.scheduleEndTime : 0,
                scheduleEndTime : lastItem?.scheduleEndTime? lastItem?.scheduleEndTime + value["duration"] : 0 + value["duration"],
                adBreaks: ads,
                new: true,
                // program: {
                //     partner: {
                //     id: key,
                //     },
                // },
                uuid: uuid,
            };
            // Find the last matching item in gridList with the same assetId
            const latestIndex = gridList?.findLastIndex(item => item.assetId === value["id"]);
            if (latestIndex !== -1) {
                // Copy adBreaks from the latest matching item in gridList to obj
                obj.adBreaks = [...gridList[latestIndex].adBreaks];
            }
            // Add obj to the draggable list with adBreaks populated
            addToDragabbleList(obj);
            setHoverTime(null);
            return;
        }
        if (type == "fillers" && value) {  
            const assetValidate = await adValidate(value["id"]);
            if (!assetValidate?.status) {
                toast.error(assetValidate?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 3000
                });
                return;
            }          
            obj = {
                assetId: value["id"],
                assetType: "FILLER",
                duration: value["duration"],
                durationInMillis: value["duration"],
                startAt: hoverTime,
                startOffsetInMillis: verifyMilliSeconds(hoverTime),
                endAt: millisecondsToHuman(verifyMilliSeconds(hoverTime) + value["duration"]),
                endOffsetInMillis: verifyMilliSeconds(hoverTime) + value["duration"],
                title: value["filename"],
                scheduledDate: getFormattedDate(),
                date: getFormattedDate(),
            };

            function isOverlapping(adBreaks, newAd) {
                return adBreaks.some((ad, index) => {
                    const adStart = ad.startOffsetInMillis;
                    const adEnd = ad.startOffsetInMillis + ad.duration;
                    const newAdStart = newAd.startOffsetInMillis;
                    const newAdEnd = newAd.startOffsetInMillis + newAd.duration;
                    const isOverlap = 
                        (newAdStart >= adStart && newAdStart < adEnd) ||
                        (newAdEnd > adStart && newAdEnd <= adEnd) ||
                        (newAdStart <= adStart && newAdEnd >= adEnd);
            
                    if (isOverlap) {
                        toast.error("New Ad cannot overlap with an existing ad.", {
                            position: toast.POSITION.BOTTOM_LEFT,
                            autoClose: 3000
                        });
                    }
                    return isOverlap;
                });
            }

            const updatedGridList = gridList.map(item => 
                item.sequence === Number(id) 
                    ? {
                        ...item, 
                        adBreaks: isOverlapping(item.adBreaks, obj) 
                            ? item.adBreaks // Keep existing adBreaks if there's overlap
                            : [...item.adBreaks, obj], // Add new obj if no overlap
                      } 
                    : item
            );
            addadsSqId(updatedGridList);
            setCategoryTab("Ads");
            setHoverTime(null);
            return;
        }
        if (type == "ads" && value) {   
            const assetValidate = await adValidate(value["id"]);
            if (!assetValidate?.status) {
                toast.error(assetValidate?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 3000
                });
                return;
            }         
            obj = {
                assetId: value["id"],
                assetType: "AD",
                duration: value["duration"],
                durationInMillis: value["duration"],
                startAt: hoverTime,
                startOffsetInMillis: verifyMilliSeconds(hoverTime),
                endAt: millisecondsToHuman(verifyMilliSeconds(hoverTime) + value["duration"]),
                endOffsetInMillis: verifyMilliSeconds(hoverTime) + value["duration"],
                title: value["filename"],
                scheduledDate: getFormattedDate(),
                date: getFormattedDate(),
            }
            function isOverlapping(adBreaks, newAd) {
                return adBreaks.some((ad, index) => {
                    const adStart = ad.startOffsetInMillis;
                    const adEnd = ad.startOffsetInMillis + ad.duration;
                    const newAdStart = newAd.startOffsetInMillis;
                    const newAdEnd = newAd.startOffsetInMillis + newAd.duration;
                    const isOverlap = 
                        (newAdStart >= adStart && newAdStart < adEnd) ||
                        (newAdEnd > adStart && newAdEnd <= adEnd) ||
                        (newAdStart <= adStart && newAdEnd >= adEnd);
            
                    if (isOverlap) {
                        toast.error("New Ad cannot overlap with an existing ad.", {
                            position: toast.POSITION.BOTTOM_LEFT,
                            autoClose: 3000
                        });
                    }
                    return isOverlap;
                });
            }

            const updatedGridList = gridList.map(item => 
                item.sequence === Number(id) 
                    ? {
                        ...item, 
                        adBreaks: isOverlapping(item.adBreaks, obj) 
                            ? item.adBreaks // Keep existing adBreaks if there's overlap
                            : [...item.adBreaks, obj], // Add new obj if no overlap
                      } 
                    : item
            );
            addadsSqId(updatedGridList);
            setCategoryTab("Ads");
            setHoverTime(null);
            return;
        }
    }

    const handelProgramDoubleClick = (data, index) => {
        let ads = [];
        if (data?.defaultAdMarkers.length) {
            const updatedAdBreaks = data?.defaultAdMarkers
            ?.sort((a, b) => a.startOffsetInMillis - b.startOffsetInMillis) // Sort adBreaks
            .map((ad, index) => ({
                ...ad,
                adSqId: index + 1, // Reassign incremental adSqId after sorting
            }));
            ads = updatedAdBreaks;
        }
        const lastItem = gridList[gridList.length - 1];
        const uuid = generateUniqueUUID(gridList);
        let obj = {
            assetId: data["id"],
            assetType: "PROGRAM",
            title: data["metadata"]["title"],
            durationInMillis: data["duration"],
            scheduleStartTime: lastItem?.scheduleEndTime? lastItem?.scheduleEndTime : 0,
            scheduleEndTime : lastItem?.scheduleEndTime? lastItem?.scheduleEndTime + data["duration"] : 0 + data["duration"],
            adBreaks: ads,
            new: true,
            // program: {
            //     partner: {
            //     id: index,
            //     },
            // },
            uuid: uuid,
        };
         // Find the last matching item in gridList with the same assetId
        const latestIndex = gridList?.findLastIndex(item => item.assetId === data["id"]);
        if (latestIndex !== -1) {
            // Copy adBreaks from the latest matching item in gridList to obj
            obj.adBreaks = [...gridList[latestIndex].adBreaks];
        }
        // Add obj to the draggable list with adBreaks populated
        addToDragabbleList(obj);
    }

    const handleProgramDelete = (index) => {
        const updatedGridList = gridList
            .filter((_, i) => i !== index) // Filter out the item at the specified index
            .map((item, i) => ({
                ...item,
                sequence: i + 1, // Reset sequence starting from 1
            }));

        // Now you can use the updatedGridList variable or set it later
        addadsSqId(updatedGridList);
    }

    const calculateMinutes = (durationInMillis) => {
        const durationInMinutes = durationInMillis / 60000;
        // const roundedMinutes = Math.round(durationInMinutes); // Rounds to nearest minute
        return durationInMinutes;
    }

    const handleZoomIn = () => {
        setZoomInOut((prevZoom) => Math.min(prevZoom + 1, 25));
    };
    
    const handleZoomOut = () => {
        setZoomInOut((prevZoom) => Math.max(prevZoom - 1, 1));
    };
    
    const getAssetInfo = (sequenceId) => {
        const matchedAsset = gridList?.find(item => item.sequence === Number(sequenceId));
        // setAssetInfo(matchedAsset || null);
        // setSeqId(sequenceId);
        if (matchedAsset || sequenceId == 1) {
            setAssetInfo(matchedAsset);
            setSeqId(sequenceId);
        } else if (gridList?.length > 0) {
            const lastItem = gridList[gridList.length - 1];
            // const lastItem = gridList[0];
            setAssetInfo(lastItem);
            setSeqId(lastItem.sequence);
        } else {
            setAssetInfo(null);
            setSeqId(null); // Optional: Handle cases where gridList is empty
        }
    }

    const handlecategoryTab = (tab) => {
        setCategoryTab(tab);
    };

    const setAdsTime = (e , adSqId, sequenceId) => {
        let convertTime = verifyMilliSeconds(e);
        const updatedGridList = gridList.map(item => {
            if (item.sequence === sequenceId) {
                return {
                    ...item,
                    adBreaks: item.adBreaks.map(ad => {
                        if (ad.adSqId === adSqId) {
                            const startOffsetInMillis = convertTime;
                            const endOffsetInMillis = startOffsetInMillis + (ad.durationInMillis || 0);
                            return {
                                ...ad,
                                startOffsetInMillis,
                                endOffsetInMillis
                            };
                        }
                        return ad;
                    })
                };
            }
            return item;
        });
        setGridList(updatedGridList);
    }

    // const setDurationTime = (e , adSqId, sequenceId) => {
    //     let convertTime = verifyMilliSeconds(e);
    //     const updatedGridList = gridList.map(item => {
    //         if (item.sequence === sequenceId) {
    //             return {
    //                 ...item,
    //                 adBreaks: item.adBreaks.map(ad => {
    //                     if (ad.adSqId === adSqId) {
    //                         const durationInMillis = convertTime;
    //                         const endOffsetInMillis = durationInMillis + (ad.startOffsetInMillis || 0);
    //                         return {
    //                             ...ad,
    //                             durationInMillis,
    //                             endOffsetInMillis
    //                         };
    //                     }
    //                     return ad;
    //                 })
    //             };
    //         }
    //         return item;
    //     });        
    //     setGridList(updatedGridList);
    // }

    const deleteEvent = (seqId, sequenceId) => {
        const updatedGridList = gridList.map(item => {
            if (item.sequence === sequenceId) {
                // Filter out the object in adBreaks where adSqId matches seqId
                return {
                    ...item,
                    adBreaks: item.adBreaks.filter(ad => ad.adSqId !== seqId)
                };
            }
            return item;
        });
        addadsSqId(updatedGridList);
    }

    const adAdsOnClick = async (sequenceId) => {
        let value = locationState?.state?.defaultFiller;
        const assetValidate = await adValidate(value["id"]);
            if (!assetValidate?.status) {
                toast.error(assetValidate?.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 3000
                });
                return;
            } 
        let updatedGridList = gridList.map(item => {
            if (item.sequence === Number(sequenceId)) {
                const lastAdBreak = item.adBreaks[item.adBreaks.length - 1];
                const lastStartOffset = lastAdBreak ? lastAdBreak.endOffsetInMillis : 0;
                const newStartOffset = lastStartOffset; // Adjust as needed for increment
    
                let obj = {
                    assetId: value["id"],
                    assetType: "AD",
                    duration: value["duration"],
                    durationInMillis: value["duration"],
                    startAt: millisecondsToHuman(newStartOffset),
                    startOffsetInMillis: newStartOffset,
                    endAt: millisecondsToHuman(newStartOffset + verifyMilliSeconds("00:00:30")),
                    endOffsetInMillis: newStartOffset + verifyMilliSeconds("00:00:30"),
                    title: value["filename"],
                    scheduledDate: getFormattedDate(),
                    date: getFormattedDate(),
                };
    
                return { ...item, adBreaks: [...item.adBreaks, obj] };
            }
            return item;
        });
    
        addadsSqId(updatedGridList);
    };

    const selectedFiller = (id, adSqId, sequenceId) => {
        const matchedFiller = fillerList.find(item => item.id === Number(id));
        const updatedGridList = gridList.map(item => {
            if (item.sequence === sequenceId) {
                return {
                    ...item,
                    adBreaks: item.adBreaks.map(ad => {
                        if (ad.adSqId === adSqId) {
                            const assetId = matchedFiller["id"];
                            const duration = matchedFiller["duration"];
                            const durationInMillis = matchedFiller["duration"];
                            const title = matchedFiller["title"];
                            const assetType = "FILLER";
                            return {
                                ...ad,
                                assetId,
                                duration,
                                durationInMillis,
                                title,
                                assetType,
                            };
                        }
                        return ad;
                    })
                };
            }
            return item;
        });
        setGridList(updatedGridList);
    }

    const searchProgram = (text) => {
        if (text) {
            const filterPrograms = originalProgramList?.filter((data) => {
                return data?.metadata?.title?.toLowerCase().includes(text.toLowerCase());
            });
            setProgramList(filterPrograms);
        } else {
            // If text is empty or null, show the original program list
            setProgramList(originalProgramList);
        }
        setSearchedText(text);
    };

    return (
        <div className={`content-body SchedulingDetail SchedulingLoop scheduling-loop-content-body ${appTheme}`}>
            <div className="dashboard-content flex schedule-loop-view">
                <div className="left">
                    <div>
                        <h3 className='schedule-title-header title-header mb-3'>Scheduling</h3>
                        <div className='list-box'>
                            <div className="search-container" style={{height: '44px'}}>
                                <input
                                    id="search-input"
                                    className="search-box"
                                    type="text"
                                    placeholder="Search program"
                                    style={{
                                    width: '100%',
                                    height: '40px',
                                    color: appTheme === "dark" ? '#ffffff' : 'black',
                                    borderRadius: '6px',
                                    padding: '0 10px',
                                    }}
                                    value={searchedText}
                                    onChange={(e)=>{searchProgram(e.target.value)}}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            searchProgram(e.target.value); // Trigger the button's onClick function
                                        }
                                    }}
                                />
                                <button
                                    className="search-button"
                                    aria-label="Search"
                                    style={{
                                    border: 'none',
                                    cursor: 'pointer',
                                    padding: '0',
                                    backgroundColor: 'transparent',
                                    color: appTheme === "dark" ? '#ffffff' : 'black',
                                    }}
                                >
                                    <img
                                    src={icroundsearch}
                                    alt="Search"
                                    className="search-icon"
                                    style={{
                                        filter: appTheme === "dark" ? 'invert(100%) brightness(150%)' : 'none',
                                    }}
                                    />
                                </button>
                            </div>

                            <div className="channel-listing-cseq">
                                <div className="list-wrapper" onDragStart={channelDragStart} >
                                    {['Programs', 'Promos', 'Fillers'].map((tab, index) => {
                                        return (
                                                <div
                                                    key={index}
                                                    className={`item-wrapper ${
                                                        tab == selectedTab ? "active" : ""
                                                    }`}
                                                >
                                                    <div
                                                        className="list-item"
                                                        data-id={index}
                                                        onClick={(e) => {
                                                            tab == selectedTab
                                                            ? setSelectedTab("")
                                                            : setSelectedTab(tab);
                                                        }}
                                                    >
                                                    <img src={ellipse} width={10} />
                                                    <div className="channel-name" data-id={index}>
                                                    {tab}
                                                    </div>
                                                    <span className="channel-arrow"></span>
                                                </div>
                                                <ul className="nexted-list">
                                                    {
                                                        tab === "Programs" ?
                                                            programList?.length?
                                                            programList?.map((data, index) => {
                                                                return (
                                                                    <li
                                                                        key={`${index}`}
                                                                        className="nexted-list-item"
                                                                        data-type="programs"
                                                                        draggable={locationState?.state?.permission === "edit"? true : false}
                                                                        // draggable={true}
                                                                        data-value={JSON.stringify(data)}
                                                                        data-key={index}
                                                                        onDoubleClick={() => {handelProgramDoubleClick(data, index)}}
                                                                        title={data?.metadata?.title}
                                                                    >
                                                                        <span className="program-name" data-key={index}>
                                                                            {data?.metadata?.title}
                                                                        </span>
                                                                    </li>
                                                                )
                                                            })
                                                            :
                                                            <span className="program-name text-center no-list-data">
                                                                No programs available.
                                                            </span>
                                                        :
                                                        tab === "Promos" ?
                                                            promoList?.length?
                                                            promoList?.map((data, index) => {
                                                                return (
                                                                    <li
                                                                        key={`${index}`}
                                                                        className="nexted-list-item"
                                                                        data-type="promos"
                                                                        draggable={locationState?.state?.permission === "edit"? true : false}
                                                                        // draggable={true}
                                                                        data-value={JSON.stringify(data)}
                                                                        data-key={index}
                                                                        title={data?.metadata?.title}
                                                                    >
                                                                        <span className="program-name" data-key={index}>
                                                                            {data?.metadata?.title}
                                                                        </span>
                                                                    </li>
                                                                )
                                                            })
                                                            :
                                                            <span className="program-name text-center no-list-data">
                                                                No promos available.
                                                            </span>
                                                        :
                                                        tab === "Fillers"?
                                                            fillerList?.length?
                                                            fillerList?.map((data, index) => {
                                                                return (
                                                                    <li
                                                                        key={`${index}`}
                                                                        className="nexted-list-item"
                                                                        data-type="fillers"
                                                                        draggable={locationState?.state?.permission === "edit"? true : false}
                                                                        // draggable={true}
                                                                        data-value={JSON.stringify(data)}
                                                                        data-key={index}
                                                                        title={data?.filename}
                                                                    >
                                                                        <span className="program-name" data-key={index}>
                                                                            {data?.filename}
                                                                        </span>
                                                                    </li>
                                                                )
                                                            })
                                                            :
                                                            <span className="program-name text-center no-list-data">
                                                                No fillers available.
                                                            </span>
                                                        :
                                                        ''
                                                    }
                                                </ul>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className="ads mt-5" onDragStart={channelDragStart}>
                                <button className="btn btn-danger w-100" data-type="ads" data-duration='00:00:30' data-value={JSON.stringify(locationState?.state?.defaultFiller)} draggable="true">
                                    + Ads
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-100 p-2 right">
                    <div className="border-right-container p-2">
                        <div className="top-content">
                            <div className="left-side">
                                <h3>{locationState?.state?.channelName}</h3>
                            </div>
                            <div className="right-side d-flex">
                                <a className='btn btn-revert mx-2 p-1 bg-secondary'><img src={revertIcon} width={35}/></a>
                                {
                                    locationState?.state?.permission === "edit" &&
                                    <button className='btn btn-primary mx-2' onClick={update_schedule}>Update</button>
                                }
                                <button className='btn btn-danger mx-2'>Auto Schedule</button>
                            </div>
                        </div>
                        <div className="bottom-content row mt-2">
                            <div className="middle-content col-6">
                                <div className="border-middle-container rounded">
                                    <div className="middle-up row">
                                        <div class="d-flex flex-column align-items-center zoom col-2">
                                            <a class="btn btn-secondary btn-circle" onClick={handleZoomIn}>
                                                {/* <i class="bi bi-search"></i> */}
                                                <img src={zoomInIcon} width={30}/>
                                            </a>
                                            <div class="vertical-line my-2"></div>
                                            <a class="btn btn-secondary btn-circle" onClick={handleZoomOut}>
                                                {/* <i class="bi bi-search"></i> */}
                                                <img src={zoomOutIcon} width={30}/>
                                            </a>
                                        </div>
                                        <div className="schedule-details col-10">
                                            <div>
                                                <table className={`table table-striped table-hover ${appTheme === "light"? "border":""}`}>
                                                    <thead>
                                                        <tr>
                                                            <th>Total scheduled</th>
                                                            <th className="text-center">Number</th>
                                                            <th className="text-center">HH:MM:SS</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Total Scheduled</td>
                                                            <td className="text-center">{totalDuration?.totalNum}</td>
                                                            <td className="text-center">{totalDuration?.duration}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Videos</td>
                                                            <td className="text-center">{totalVideoDuration?.totalNum}</td>
                                                            <td className="text-center">{totalVideoDuration?.duration}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Ads</td>
                                                            <td className="text-center">{totalAdDuration?.totalNum}</td>
                                                            <td className="text-center">{totalAdDuration?.duration}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Promos</td>
                                                            <td className="text-center">{totalPromosDuration?.totalNum}</td>
                                                            <td className="text-center">{totalPromosDuration?.duration}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Secondary</td>
                                                            <td className="text-center">0</td>
                                                            <td className="text-center">00:00:00</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="middle-down">
                                        <div className="content-wrapper">
                                            <table className="scheduling-table tbl-50-per">
                                                <tbody
                                                    data-type="table-body"
                                                    role="none"
                                                    onDragStart={handleDragStart}
                                                    onDragOver={handleDragOver}
                                                    onDrop={handleDrop}
                                                >   
                                                <div className="row w-100">
                                                    {/* Render timeSlots in a separate loop */}
                                                    <div className="time-slots-container col-2 p-2 text-center">
                                                        {timeSlots?.map((slot, index) => (
                                                            <div 
                                                                key={index}
                                                                style={{ width: "100px", height: `${30 * zoomInOut}px` }}
                                                                className="time-slot"
                                                            >
                                                                <p>{slot}</p>  {/* Customize this to display relevant time slot info */}
                                                            </div>
                                                        ))}
                                                    </div>
                                                     {/* Render gridList as you already have it */}
                                                     <div className="grid-list-container col-10 p-2">
                                                        { gridList?.length !== 0?
                                                            gridList?.map((data, index) => {
                                                                return (
                                                                    <section 
                                                                        className="d-flex px-2" 
                                                                        id={`sequenceId${data?.sequence}`}
                                                                        onDragOver={(e) => handleDragOver2(e, data.sequence)}
                                                                    >
                                                                        <div
                                                                            style={{height: `${calculateMinutes(data?.durationInMillis + data?.adBreaks?.reduce((sum, ad) => sum + ad.durationInMillis, 0) || 0)*zoomInOut}px`, width: "36px", position:"relative"}}
                                                                        >   
                                                                            {linePosition !== null && hoveredId === data?.sequence && (
                                                                                <div
                                                                                    style={{
                                                                                        position: "absolute",
                                                                                        pointerEvents: "none",
                                                                                        top: `${linePosition}px`,
                                                                                        zIndex: "9999",
                                                                                        left: "-8",
                                                                                        width: "460px",
                                                                                        height: "2px",
                                                                                        backgroundColor: "blue",
                                                                                        pointerEvents: "none"
                                                                                    }}
                                                                                >
                                                                                    <span
                                                                                        style={{
                                                                                            position: "absolute",
                                                                                            zIndex: "99999999",
                                                                                            top: "-22px",
                                                                                            left: "0px",
                                                                                            backgroundColor: "white",
                                                                                            padding: "2px 5px",
                                                                                            fontSize: "12px",
                                                                                        }}
                                                                                    >
                                                                                        {hoverTime} min
                                                                                    </span>
                                                                                </div>
                                                                            )}  
                                                                            {
                                                                                data?.adBreaks?.length !== 0?
                                                                                    data?.adBreaks?.map((data) => {
                                                                                        if (data?.assetType === "AD" || data?.assetType === "FILLER") {
                                                                                            return (
                                                                                                <span style={{position: "absolute" , zIndex: `${data?.adSqId}`, top: `${calculateMinutes(data?.startOffsetInMillis)*zoomInOut}px`, height: "0px"}}>
                                                                                                    <img src={adMarkers} width={35} style={{position: "absolute", top: "-4px"}}/>
                                                                                                </span>
                                                                                            )
                                                                                        }
                                                                                        else if (data?.assetType === "PROMO") {
                                                                                            return (
                                                                                                <span style={{position: "absolute" , zIndex: `${data?.adSqId}`, top: `${calculateMinutes(data?.startOffsetInMillis)*zoomInOut}px`, height: "0px"}}>
                                                                                                    <img src={adMarkers} width={35} style={{position: "absolute", top: "-4px", filter: "invert(30%) sepia(90%) saturate(400%) hue-rotate(75deg) brightness(90%) contrast(90%)"}}/>
                                                                                                </span>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                :
                                                                                ''
                                                                            }
                                                                        </div>
                                                                        <tr
                                                                            className="dragabble"
                                                                            onClick={() => {getAssetInfo(data?.sequence)}}
                                                                            draggable={locationState?.state?.permission === "edit"? true : false}
                                                                            // draggable={true}
                                                                            data-type="draggable-list"
                                                                            key={`${index}`}
                                                                            data-value={JSON.stringify(data)}
                                                                            data-id={data?.sequence}
                                                                            style={{height: `${calculateMinutes(data?.durationInMillis + data?.adBreaks?.reduce((sum, ad) => sum + ad.durationInMillis, 0) || 0)*zoomInOut}px`, width: "95%", overflow: "hidden", border : `${assetInfo?.sequence === data?.sequence ? "1px solid transparent" : ""}`, borderImage: `${assetInfo?.sequence === data?.sequence ? "linear-gradient(to right, #22bbbe, #7dad41, #f4b719, #e55824, #b52c80) 1" : ""}`}}
                                                                            title={data?.title}
                                                                        >
                                                                            <td data-id={data?.sequence} style={{ background: index % 4 === 0 ? "#FFC374" : index % 4 === 1 ? "#4AD2C9" : index % 4 === 2 ? "#8E73EF" : "#536EFF", border : `${assetInfo?.sequence === data?.sequence ? "4px solid rgba(0, 0, 0, 0.9)" : ""}`}}>
                                                                                <div className="table-content2" style={{ background: "rgba(255, 255, 255, 0.65)", pointerEvents: "none"}}>
                                                                                    <div className="title-schedule-container"> 
                                                                                        <p className="px-2 fw-bold m-0 program-title" style={{color: "#212529"}}>
                                                                                            {data?.title}
                                                                                        </p>
                                                                                        <p className="px-2 fw-bold schedule-time" style={{color: "#212529"}}>
                                                                                            {`${millisecondsToHuman(data?.scheduleStartTime)} -  ${millisecondsToHuman(data?.scheduleEndTime)}`}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="position-relative">
                                                                                        <a className="p-2 table-icon position-absolute" onClick={() => {handleProgramDelete(index)}} style={{filter: "invert(0)", pointerEvents: "auto"}}>
                                                                                            <img src={deleteIcon} width={18} />
                                                                                        </a>
                                                                                        <a className="p-2 table-adbreaks-count position-absolute" style={{filter: "invert(0)", pointerEvents: "auto"}}>
                                                                                            <span>{data?.adBreaks.length}</span>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="table-content2" style={{ background: "rgba(255, 255, 255, 0.65)", pointerEvents: "none"}}>
                                                                                    
                                                                                    
                                                                                </div> */}
                                                                            </td>
                                                                        </tr>
                                                                    </section>
                                                                )
                                                            })
                                                            :
                                                            <td colspan="5" class="text-center no-data">
                                                                No data has been scheduled.
                                                                {/* Nothing scheduled yet */}
                                                            </td>
                                                        }
                                                     </div>
                                                </div>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="right-side-content col-6">
                                <div className="background-right-container rounded">
                                    <div className={`top-content-right ${appTheme === "light"? "rounded shadow-sm": ""}`}>
                                        <div className="left-side">
                                            <h5 className="py-3 px-3">{assetInfo?.title}</h5>
                                        </div>
                                        <div className="asset-duration-cont row px-1 pb-3">
                                            <div className="col-5 start-end-time">
                                                <h5 className="px-2">{
                                                assetInfo?
                                                `${millisecondsToHuman(assetInfo?.scheduleStartTime)} - ${millisecondsToHuman(assetInfo?.scheduleEndTime)}`
                                                :
                                                "00:00:00 - 00:00:00"
                                                }</h5>
                                            </div>
                                            <div className="col-7 d-flex video-ads-time justify-content-end">
                                                <h5 className="me-1"><span>Video</span> - {assetInfo? millisecondsToHuman(assetInfo?.durationInMillis) : "00:00:00"}</h5>
                                                {
                                                    categoryTab === "Ads" ?
                                                        <h5 className="ms-1"><span>Ads</span> - 
                                                            {assetInfo?
                                                                millisecondsToHuman(assetInfo?.adBreaks
                                                                    ?.filter(data => data?.assetType === "AD" || data?.assetType === "FILLER")
                                                                    ?.reduce((total, ad) => total + ad.durationInMillis, 0)
                                                                )
                                                                :
                                                                "00:00:00"
                                                            }
                                                        </h5>
                                                    :
                                                    categoryTab === "Promos" ?
                                                        <h5 className="ms-1"><span>Promos</span> - 
                                                            {assetInfo?
                                                                millisecondsToHuman(assetInfo?.adBreaks
                                                                    ?.filter(data => data?.assetType === "PROMO")
                                                                    ?.reduce((total, ad) => total + ad.durationInMillis, 0)
                                                                )
                                                                :
                                                                "00:00:00"
                                                            }
                                                        </h5>
                                                    :
                                                    categoryTab === "Secondary" ? 
                                                        <h5 className="ms-1"><span>Sec</span> - 
                                                            {assetInfo?
                                                                millisecondsToHuman(assetInfo?.adBreaks
                                                                    ?.filter(data => data?.assetType === "FILLER")
                                                                    ?.reduce((total, ad) => total + ad.durationInMillis, 0)
                                                                )
                                                                :
                                                                "00:00:00"
                                                            }
                                                        </h5>
                                                    :
                                                    ''
                                                }

                                            </div>
                                        </div>
                                    </div>
                                    <div className={`bottom-content-right ${appTheme === "light"? "rounded shadow-sm": ""}`}>
                                        <div className="tabs mt-4 p-2">
                                            <ul className={`channel-category-tab px-1 ${appTheme === "light" ? "border shadow-sm": ""}`}>
                                                <li className={categoryTab === "Ads" ? "active" : ""}>
                                                <a onClick={() => handlecategoryTab("Ads")}>Ads</a>
                                                </li>
                                                <li className={categoryTab === "Promos" ? "active" : ""}>
                                                <a onClick={() => handlecategoryTab("Promos")}>Promos</a>
                                                </li>
                                                <li className={categoryTab === "Secondary" ? "active" : ""}>
                                                <a disable onClick={() => {handlecategoryTab("Secondary");}}>Secondary</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className={`ads-table mx-2 my-3 p-2 rounded ${appTheme === "light"? "shadow-sm border" : ""}`}>
                                            <table className={`table ${appTheme === "light"? "border table-striped table-hover" : ""}`}>
                                                <thead>
                                                    <tr>
                                                        <th>Name <span className="header-icon"></span></th>
                                                        <th>Start Time <span className="header-icon"></span></th>
                                                        <th>Duration</th>
                                                        <th>
                                                            {
                                                                locationState?.state?.permission === "edit" &&
                                                                "Actions"
                                                            }
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                { categoryTab === "Ads"?
                                                    assetInfo?.adBreaks?.some((data) => data?.assetType === "AD" || data?.assetType === "FILLER")?
                                                        assetInfo?.adBreaks
                                                        ?.filter((data) => data?.assetType === "AD" || data?.assetType === "FILLER")
                                                        .map((data, index) => {
                                                            return (
                                                                <tr key={data.id}>
                                                                    <td style={{width:"40%"}}>   
                                                                        <select value={data?.assetId} onChange={(e)=> {selectedFiller(e.target.value, data?.adSqId, assetInfo?.sequence)}}>
                                                                            {
                                                                                fillerList?.map((fillers) => {
                                                                                    return <option value={fillers?.id} >{fillers?.filename}</option>
                                                                                })
                                                                            }
                                                                        </select>
                                                                    </td>
                                                                    <td style={{width:"25%"}}>
                                                                        <TimePicker
                                                                            value={millisecondsToHuman(data?.startOffsetInMillis)}
                                                                            hourPlaceholder="HH"
                                                                            minutePlaceholder="mm"
                                                                            secondPlaceholder="ss"
                                                                            maxDetail="second"
                                                                            // maxTime={parentData?.extendedProps.endAt}
                                                                            // minTime={parentData?.extendedProps.startAt}
                                                                            format="HH:mm:ss"
                                                                            disableClock={true}
                                                                            onChange={(e) => {
                                                                                setAdsTime(e, data?.adSqId, assetInfo?.sequence);
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td style={{width:"25%"}}>
                                                                        <div className="ads-duration">
                                                                            {millisecondsToHuman(data?.durationInMillis)}
                                                                        </div>
                                                                    </td>
                                                                    {/* <td>
                                                                        <TimePicker
                                                                            value={millisecondsToHuman(data?.durationInMillis)}
                                                                            hourPlaceholder="HH"
                                                                            minutePlaceholder="mm"
                                                                            secondPlaceholder="ss"
                                                                            maxDetail="second"
                                                                            // maxTime={parentData?.extendedProps.endAt}
                                                                            // minTime={parentData?.extendedProps.startAt}
                                                                            format="HH:mm:ss"
                                                                            disableClock={true}
                                                                            onChange={(e) => {
                                                                                setDurationTime(e, data?.adSqId, assetInfo?.sequence);
                                                                            }}
                                                                        />
                                                                    </td> */}
                                                                    <td style={{width:"10%"}}>
                                                                        {
                                                                            locationState?.state?.permission === "edit" &&
                                                                            <div className="table-action-group">
                                                                                <div className='icon-group'>
                                                                                    {/* <span className="table-icon me-1"><img src={editIcon} /></span> */}
                                                                                    <span className="table-icon" onClick={()=> {deleteEvent(data?.adSqId, assetInfo?.sequence)}}><img src={deleteIcon} /></span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                        :
                                                        <td colspan="4" class="text-center no-data" style={{height: "210px"}}>
                                                            No ads to display.
                                                        </td>
                                                    :
                                                    categoryTab === "Promos"?
                                                    assetInfo?.adBreaks?.some((data) => data?.assetType === "PROMO")?
                                                        assetInfo?.adBreaks
                                                        ?.filter((data) => data?.assetType === "PROMO")
                                                        .map((data) => {
                                                            return (
                                                                <tr key={data.id}>
                                                                    <td>{data?.title}</td>
                                                                    <td>
                                                                        <TimePicker
                                                                            value={millisecondsToHuman(data?.startOffsetInMillis)}
                                                                            hourPlaceholder="HH"
                                                                            minutePlaceholder="mm"
                                                                            secondPlaceholder="ss"
                                                                            maxDetail="second"
                                                                            // maxTime={parentData?.extendedProps.endAt}
                                                                            // minTime={parentData?.extendedProps.startAt}
                                                                            format="HH:mm:ss"
                                                                            disableClock={true}
                                                                            onChange={(e) => {
                                                                                setAdsTime(e, data?.adSqId, assetInfo?.sequence);
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        {millisecondsToHuman(data?.durationInMillis)}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            locationState?.state?.permission === "edit" &&
                                                                            <div className="table-action-group">
                                                                                <div className='icon-group'>
                                                                                    {/* <span className="table-icon me-1"><img src={editIcon} /></span> */}
                                                                                    <span className="table-icon ms-3" onClick={()=> {deleteEvent(data?.adSqId, assetInfo?.sequence)}}><img src={deleteIcon} /></span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                        :
                                                        <td colspan="4" class="text-center no-data" style={{height: "210px"}}>
                                                            No promos to display.
                                                        </td>
                                                    :
                                                    categoryTab === "Secondary"?
                                                    <td colspan="4" class="text-center no-data" style={{height: "210px"}}>
                                                        No Secondary to display.
                                                    </td>
                                                    :
                                                    ''
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        {
                                            categoryTab === "Ads"?
                                            <span className="mt-3 ads-table-btn p-2" onClick={() => {adAdsOnClick(assetInfo?.sequence)}}>+ Ad Break</span>
                                            :
                                            ''
                                        }
                                    </div>
                                    <div className={`bottom-container-buttons`}>
                                        <div className="buttons-container p-2 d-flex justify-content-between">
                                            <a 
                                                className={`btn btn-secondary w-50 mx-3 ${appTheme === "dark"? 'text-white': ''}`} 
                                                onClick={() => {handleProgramDelete(assetInfo?.sequence - 1)}}
                                            >Delete</a>
                                            <a 
                                                className="btn btn-danger w-50 mx-3"
                                                onClick={(e) => { 
                                                    e.preventDefault();
                                                    if ((assetInfo?.sequence - 1) > 0) {
                                                        getAssetInfo(assetInfo?.sequence - 1);
                                                        document
                                                        .getElementById(`sequenceId${assetInfo?.sequence - 1}`)
                                                        ?.scrollIntoView({ behavior: "smooth" }); // Scroll to target section
                                                    }
                                                }}
                                            >Previous</a>
                                            <a 
                                                className="btn btn-primary w-50 mx-3"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (assetInfo?.sequence < gridList.length) {
                                                        getAssetInfo(assetInfo?.sequence + 1);
                                                        document
                                                        .getElementById(`sequenceId${assetInfo?.sequence + 1}`)
                                                        ?.scrollIntoView({ behavior: "smooth" }); // Scroll to target section
                                                    }
                                                }}
                                            >Next</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default SchedulingLoopNew;