
import React, { useRef, useLayoutEffect } from "react";

// Function to calculate the node height based on the content
const calculateNodeHeight = (nodeSizingData, value, minRows = 1, maxRows) => {
  const { padding, borderWidth } = nodeSizingData;
  const rowHeight = nodeSizingData.lineHeight + padding * 2 + borderWidth * 2;
  
  // Calculate height based on the number of rows
  const lines = value.split('\n').length;
  const height = Math.min(Math.max(lines, minRows), maxRows) * rowHeight;
  
  return [height, rowHeight];
};

// Function to get the sizing data for the textarea
const getSizingData = (textarea) => {
  const computedStyle = window.getComputedStyle(textarea);
  return {
    padding: parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom),
    borderWidth: parseFloat(computedStyle.borderTopWidth) + parseFloat(computedStyle.borderBottomWidth),
    lineHeight: parseFloat(computedStyle.lineHeight),
  };
};

const TextareaAutosize = React.forwardRef(({
  maxRows,
  minRows = 1,
  value,
  onChange,
  onHeightChange = () => {},
  style,
  ...props
}, ref) => {
  const libRef = useRef(null);
  const heightRef = useRef(0);
  
  const resizeTextarea = () => {
    const node = libRef.current;
    const nodeSizingData = getSizingData(node);
    
    if (!nodeSizingData) return;

    const [height, rowHeight] = calculateNodeHeight(
      nodeSizingData,
      value || node.placeholder || 'x',
      minRows,
      maxRows,
    );

    if (heightRef.current !== height) {
      heightRef.current = height;
      node.style.setProperty('height', `${height}px`, 'important');
      onHeightChange(height, { rowHeight });
    }
  };

  useLayoutEffect(resizeTextarea, [value, minRows, maxRows]);

  return (
    <textarea
      {...props}
      ref={(element) => {
        libRef.current = element;
        if (ref) {
          if (typeof ref === 'function') {
            ref(element);
          } else {
            ref.current = element;
          }
        }
      }}
      onChange={(e) => {
        onChange(e);
        resizeTextarea();
      }}
      value={value}
    />
  );
});

export default TextareaAutosize;

