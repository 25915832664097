import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import "./ChannelActions.scss";
import Dashboardheader from "../../component/dashboard-header";
import SlideBar from "../../component/sidebar/sidebar";
import donecheck from "../../assets/svg/Action/compltedIcon.svg";
import downarrow from "../../assets/svg/Action/downarrow.svg";
import pending from "../../assets/svg/Action/pending.svg";
import error from "../../assets/svg/Action/Error.svg";
import warning from "../../assets/images/warning.png";
import processing from "../../assets/svg/Action/proccesing.svg";
import filter_alt from "../../assets/images/sortarrow.png";
import desc from "../../assets/images/desc.png";
import rightArrow from "../../assets/images/rightvector.png";
import leftArrow from "../../assets/images/Vector.png";
import filterimg from "../../assets/images/sort.png";
import override from "../../assets/svg/Action/override.svg";
import refresh from "../../assets/svg/Action/refresh.svg";
import Download from "../../assets/svg/Action/save_alt.svg";
import Delete from "../../assets/svg/Action/delete.svg";
import {
  DateStamp,
  TimeConverter,
  capitalizeFirstLetter,
  convertTimestampToTime,
  isLogin,
} from "../../service/commonFunc";
import {
  AssetPublish,
  AssetTranscode,
  AssetValidate,
  Asset_Detail,
  FillerList,
  ProgramsList_By_Partner,
  PromosList_By_Partner,
} from "../../api/api";
import Validatepopup from "../../component/popup/asset-action-popup/Validatepopup";
import { useEffect } from "react";
import Searchicon from "../../assets/svg/Action/searchicon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { baseURL } from "../../service/API_URL";
import { getInstantData } from "../../api/apiMethod";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import { permissionDetails } from "../../utility/localStoageData";
import { red } from "@material-ui/core/colors";
// import ReactTooltip from 'react-tooltip';

export default function ChannelActionsComponent() {
  const AssetActionDetail = JSON.parse(localStorage.getItem("AssetDetail"));
  const AssetPartnerDetail = JSON.parse(
    localStorage.getItem("AssetPartnerDetail")
  );
  const [actionDetail, setActionDetail] = useState([]);
  const [actionData, setActionData] = useState([]);
  const [actionType, setActionType] = useState([]);



  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipText, setTooltipText] = useState('');

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [inputPage, setInputPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuerys, setSearchQuery] = useState("");
  const [searchButtonClicked, setSearchButtonClicked] = useState(false);
  const [rotation, setRotation] = useState(0);
  const typeOptions = ["PROGRAM", "PROMO", "FILLER"];
  const [selectedType, setSelectedType] = useState("PROGRAM");
  const [permission, setpermission] = useState(false);
  const [pageJump, setPageJump] = useState(0);
  const [currentSortOrder, setCurrentSortOrder] = useState({
    sortBy: "filename",
    sortOrder: "asc",
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  React.useEffect(() => {

    document.addEventListener('click', closeDropdown);


    return () => document.removeEventListener('click', closeDropdown);
  }, []);

  async function fetchData(currentPage) {
    // const  = {
    //   partnerId: AssetPartnerDetail?.id,
    //   pageSize: itemsPerPage,
    //   pageNumber: currentPage - 1,
    //   ...(searchQuerys ? { searchQuery: searchQuerys } : null)
    // };

    const params = {
      partnerId: AssetPartnerDetail?.id,
      pageSize: itemsPerPage,
      sortBy: currentSortOrder.sortBy,
      sortOrder: currentSortOrder.sortOrder,
      pageNumber: currentPage - 1,
      ...(searchQuerys ? { searchQuery: searchQuerys } : null),
    };

    try {
      const assetDetail = await ProgramsList_By_Partner(params);
      const fillerDetail = await FillerList(params);
      const PromoDetail = await PromosList_By_Partner(params);


      if (selectedType == "PROGRAM") {

        if (assetDetail.status === true) {
          localStorage.setItem("AssetDetail", JSON.stringify(assetDetail.data));
          setActionDetail(assetDetail.data?.content);
          setActionData(assetDetail.data);
          setTotalPages(assetDetail?.data?.totalPages);
          setCurrentPage(assetDetail?.data?.number + 1);
        }
      } else if (selectedType == "FILLER") {
        if (fillerDetail.status === true) {
          localStorage.setItem(
            "fillerDetail",
            JSON.stringify(fillerDetail.data)
          );
          setActionDetail(fillerDetail.data?.content);
          setActionData(fillerDetail.data);
          setTotalPages(assetDetail?.data?.totalPages);
          setCurrentPage(assetDetail?.data?.number + 1);
        }
      } else if (selectedType == "PROMO") {

        if (PromoDetail.status === true) {

          localStorage.setItem(
            "fillerDetail",
            JSON.stringify(PromoDetail.data)
          );
          setActionDetail(PromoDetail.data?.content);
          setActionData(PromoDetail.data);
          setTotalPages(assetDetail?.data?.totalPages);
          setCurrentPage(assetDetail?.data?.number + 1);
        }
      }
      setSearchButtonClicked(false);
    } catch (error) {
      console.error(error);
    }


  }

  // Function to check if any object has transcodingStatus as "IN_PROGRESS"
  const checkTranscodingInProgress = (data) => {
    for (let i = 0; i < data.length; i++) {
      if (
        data[i].transcodingStatus === "SUBMITTED" ||
        data[i].transcodingStatus === "IN_PROGRESS"
      ) {
        return true;
      }
    }
    return false;
  };


  const getPermission = () => {
    let findEdit = permissionDetails().includes("ASSET_MANAGEMENT_EDIT");
    if (findEdit) setpermission(true);
    else setpermission(false);
  };

  useEffect(() => {
    getPermission();
    setCheckedIds([]);
    fetchData(currentPage);
  }, [currentPage, searchButtonClicked, selectedType, currentSortOrder]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (actionDetail.length > 0 && checkTranscodingInProgress(actionDetail)) {
        fetchData();
      }
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, [actionDetail]);

 
  const blankValue = (
    <>
      <img src={pending} alt="Error Image" style={{ marginRight: "8px", width: 27, height: 27 }} /> pending
    </>
  );
  const processingValue = (
    <>
      <img src={processing} alt="Error Image" style={{ marginRight: "8px", width: 27, height: 27 }} /> processing..
    </>
  );
  const errorValue = (
    <>
      <img src={error} alt="Error Image" style={{ marginRight: "8px", width: 25, height: 25 }} />Error Summary
    </>
  );
  const OVERRIDDEN = (
    <>
      <img src={override} style={{ marginRight: "8px", width: 27, height: 27 }} />  Overridden
    </>
  );

  const [validatepopup, setvalidatepopup] = useState(false);

  const [transcodeActive, setTranscodeActive] = useState(false);
  const [validateActive, setValidateActive] = useState(false);
  const [publishActive, setPublishActive] = useState(false);
  const [validatedata, setValidate] = useState(null);
  const [assetSignledata, setAssetSignledata] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentAction, setCurrentAction] = useState("");



  const actionStatus = (status) => {
    if (status === "NOT_DONE") {
      return "pending";
    }
    if (status === "ERROR") {
      return "error";
    }

    if (status == "IN_PROGRESS" || status == "SUBMITTED") {
      return "processing ";
    }
    if (status === "DONE") {
      return "complete";
    }
    if (status == null) {
      return "pending ";
    }
    if (status == "OVERRIDDEN") {
      return "OverRiden";
    }
  };

  const errorstatus = (status) => {
    if (status === "NOT_DONE") {
      return blankValue;
    }
    if (status === "OVERRIDDEN") {
      return OVERRIDDEN;
    }
    if (status === "ERROR") {
      return errorValue;
    }

    if (status == "IN_PROGRESS" || status == "SUBMITTED") {
      return processingValue;
    }
    if (status === "DONE") {
      return blankValue;
    }
    if (status == null) {
      return blankValue;
    }
  };

  const [checkedIds, setCheckedIds] = useState([]);



  const validationation = async (data) => {
    if (
      data?.validationStatus != null &&
      data?.validationStatus != "Completed"
    ) {
      const actionData = {

        assetIds: [data.id],
      };

      const validate = await AssetValidate(actionData);

      setValidate(validate?.data?.data[0]);
      setAssetSignledata(data);
      setvalidatepopup(true);
    }
  };
 
  const AssetActions = async (actionType, state) => {
    let assemblyType = "";
    let loaderMessage = "";
  
   
    if (actionType === "TRANSCODE") {
      assemblyType = state ? "AWS" : "RUNN_MEDIA";
      loaderMessage = "Processing Transcoding to Playout... Please wait.";
    } else if (actionType === "VALIDATE") {
     
      assemblyType = "EXTERNAL"; 
      loaderMessage = "Processing Validation... Please wait.";
    } else if (actionType === "RUNN_MEDIA" || actionType === "AWS") {
      assemblyType = actionType === "RUNN_MEDIA" ? "RUNN_MEDIA" : "AWS";
      loaderMessage = actionType === "RUNN_MEDIA"
        ? "Publishing content to Runn Playout... Please wait."
        : "Publishing content to AWS Mediatailor... Please wait.";
    }
  
    const actionData = {
      actionType: actionType,
      assetIds: checkedIds,
      videoType: selectedType,
      transcodingProvider: state ? "AWS_MEDIA_CONVERT" : "R_MEDIA_ENCODER",
      assemblyType: assemblyType,  
    };
  
    try {
      setIsProcessing(true);
      setCurrentAction(loaderMessage);
  
      if (actionType === "TRANSCODE") {
        const transcode = await AssetTranscode(actionData);
        if (transcode?.data?.status === true) {
          toast.success("Transcode operation initiated successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
          resetState();
        } else {
          handleError();
        }
      } else if (actionType === "VALIDATE") {
        console.log("Validation Payload:", actionData); 
        const validate = await AssetValidate(actionData);
        console.log("Validation Response:", validate); 
  
        if (validate?.data?.status === true) {
          setValidate(validate?.data?.data[0]);
          toast.success("Content validated successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
          resetState();
        } else {
          console.warn("Validation failed:", validate);
          handleError();
        }
      } else if (actionType === "RUNN_MEDIA" || actionType === "AWS") {
        const publish = await AssetPublish(actionData);
        if (publish?.data?.status === true) {
          toast.success("Content published successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
          resetState();
        } else {
          handleError();
        }
      }
    } catch (error) {
      console.error(`Error during ${actionType}:`, error);
      toast.error(`An error occurred during ${actionType.toLowerCase()}`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    } finally {
      setIsProcessing(false);
      setCurrentAction("");
    }
  };
  
  // Helper function to reset UI state after successful operation
  const resetState = () => {
    setCheckedIds([]);
    setChecked(false);
    setDisabledFiled(false);
    setPublishActive(false);
    fetchData(currentPage);
  };
  
  // Helper function to handle generic error cases
  const handleError = () => {
    toast.error("Something went wrong, please try again later", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000,
    });
  };
  

  setTimeout(() => {
    setIsProcessing(false);
  }, 3000)

  
  useEffect(() => {
    if (actionData && actionData.totalElements) {
      setTotalPages(Math.ceil(actionData.totalElements / itemsPerPage));
    }
  }, [actionData, itemsPerPage]);

  // Handle input change, restrict the input to valid page range
  const handleInputChange = (event) => {
    // Set the page number as you type, but don't update page yet
    setInputPage(event.target.value);
  };

  // Handle the page change when "Enter" is pressed
  const handlePageKeyPress = (event) => {
    if (event.key === "Enter") {
      const newPage = Math.max(1, Math.min(totalPages, inputPage)); // Ensure valid page number
      setCurrentPage(newPage);
      setInputPage(newPage); // Optionally sync input with the actual page number
      fetchData(newPage); // Call fetchData with new page number
    }
  };

  // Update page when the arrows are clicked
  const handlePageChange = (direction) => {
    const newPage = direction === "next" ? currentPage + 1 : currentPage - 1;
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      setInputPage(newPage); // Sync input with the actual page number
      fetchData(newPage); // Call fetchData with new page number
    }
  };

  const [sortOrders, setSortOrders] = useState({});
  useEffect(() => {
    isLogin();
    const initialSortOrders = {
      filename: "asc",
      duration: "",
      dateUploaded: "",
      dateValidated: "",
      datePublished: "",
      dateTranscoded: "",
    };
    setSortOrders(initialSortOrders);
  }, [selectedType]);

  const handleSort = async (field) => {
    let currentSortOrder = "desc"; // Default sort order
    let sortField = field;
    // Check if the field was previously sorted and toggle the sort order
    if (sortOrders[field]) {
      currentSortOrder = sortOrders[field] === "asc" ? "desc" : "asc";
    }

    const params = {
      partnerId: AssetPartnerDetail?.id,
      sortBy: field,
      sortOrder: currentSortOrder,
      pageNumber: currentPage - 1,
      pageSize: itemsPerPage,
    };
    setCurrentSortOrder({
      sortBy: sortField,
      sortOrder: currentSortOrder,
    });

    let updatedData = {};

    if (selectedType === "PROGRAM") {
      updatedData = await ProgramsList_By_Partner(params);
    } else if (selectedType === "FILLER") {
      updatedData = await FillerList(params);
    } else if (selectedType === "PROMO") {
      updatedData = await PromosList_By_Partner(params);
    }

    if (updatedData.status === true) {
      const newData = updatedData.data;
      const newDataContent = newData?.content || [];

      localStorage.setItem(
        selectedType === "PROGRAM"
          ? "AssetDetail"
          : selectedType === "FILLER"
            ? "fillerDetail"
            : "PromoDetail",
        JSON.stringify(newData)
      );
      setActionDetail(newDataContent);

      const newSortOrders = Object.keys(sortOrders).reduce((acc, key) => {
        if (key === field) {
          acc[key] = currentSortOrder;
        } else {
          acc[key] = ""; // Reset other sort orders
        }
        return acc;
      }, {});

      setSortOrders(newSortOrders);
    }
  };

  const getSortIcon = (field) => {
    if (sortOrders[field] == "") {
      return <img src={filterimg} alt="filterimg" />;
    }
    if (sortOrders[field] == "desc") {
      return <img src={desc} alt="desc" />; // Image for ascending order
    } else {
      return <img src={filter_alt} alt="Ascending" />; // Image for ascending order
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {

      setSearchButtonClicked(true);

    }
  };

  const [disabledFiled, setDisabledFiled] = useState(false);

  const [publishvalidation, setPublishvalidation] = useState(true);

  const handleCheckboxChange = (e, data, index) => {
    const checked = e.target.checked;
    // Update checkedIds based on checkbox status
    let updatedCheckedIds = [...checkedIds];
    if (checked) {
      if (data?.transcodingStatus == null) {
        setTranscodeActive(true);
        setValidateActive(false);
        setPublishActive(false);
        setDisabledFiled(data?.transcodingStatus);
      } else if (
        data?.transcodingStatus == "SUBMITTED" ||
        data?.transcodingStatus == "IN_PROGRESS"
      ) {
        setTranscodeActive(false);
        setValidateActive(false);
        setPublishActive(false);
        setDisabledFiled(data?.transcodingStatus);
      } else if (data?.transcodingStatus == "ERROR") {
        setTranscodeActive(true);
        setValidateActive(false);
        setPublishActive(false);
        setDisabledFiled(data?.transcodingStatus);
      } else if (data?.transcodingStatus == "COMPLETED") {
        if (
          data?.validationStatus == null ||
          data?.validationStatus == "ERROR"
        ) {
          setTranscodeActive(false);
          setValidateActive(true);
          setPublishActive(false);
          setDisabledFiled({
            transcodingStatus: data?.transcodingStatus,
            validationStatus: data?.validationStatus,
          });
        } else if (data?.validationStatus == "OVERRIDDEN") {
          setTranscodeActive(false);
          setValidateActive(true);
          setPublishActive(true);
          setDisabledFiled({
            transcodingStatus: data?.transcodingStatus,
            validationStatus: data?.validationStatus,
          });
        } else if (data?.validationStatus == "COMPLETED") {
          if (data?.published == false || data?.published == null) {
            setTranscodeActive(false);
            setValidateActive(false);
            setPublishActive(true);
            setDisabledFiled({
              transcodingStatus: data?.transcodingStatus,
              validationStatus: data?.validationStatus,
              published: data?.published,
            });
          } else if (
            data?.published == true ||
            data?.published == "COMPLETED"
          ) {
            setTranscodeActive(false);
            setValidateActive(false);
            setPublishActive(false);
            setDisabledFiled({
              transcodingStatus: data?.transcodingStatus,
              validationStatus: data?.validationStatus,
              published: data?.published,
            });
          }
        }
      }
      updatedCheckedIds.push(data.id);
    } else {
      updatedCheckedIds = updatedCheckedIds.filter((id) => id !== data.id);
    }


    setCheckedIds(updatedCheckedIds);
    if (updatedCheckedIds.length == 0) {
      setChecked(false);
      setDisabledFiled(false);
      setPublishActive(false);
    } else {
      setChecked(true);
      if (updatedCheckedIds.length === actionDetail.length) {
        setAllcheck(false);
      } else {
        setAllcheck(true);
      }
    }
  };

  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(-1);
  };
  const [isChecked, setChecked] = useState(false);
  const [allcheck, setAllcheck] = useState(false);

  const handleSelectAllClick = () => {
    let updatedCheckedIds = [...checkedIds];
    if (checkedIds.length == 0) {
      if (isChecked) {
        setCheckedIds([]);
        setChecked(false);
        setDisabledFiled(false);
        setPublishActive(false);
        setAllcheck(false);
      } else {
        function findValue(array, condition) {
          for (let i = 0; i < array.length; i++) {
            if (condition(array[i])) {
              return array[i];
            }
          }
          return null;
        }
        const checkedData = findValue(
          actionDetail,
          (element) => element?.transcoded === false
        );
        actionDetail?.map((data) => {
          if (data?.validationStatus == checkedData.validationStatus) {
            updatedCheckedIds.push(data.id);
            setDisabledFiled(data?.validationStatus);
            // setCheckedIds(updatedCheckedIds)
            setChecked(true);
          } else {
            setCheckedIds([]);
            setChecked(false);
            setDisabledFiled(false);
            setPublishActive(false);
          }
        });
        setChecked(true);
        setCheckedIds(updatedCheckedIds);
      }
    } else {
      actionDetail?.map((data, index) => {
        if (
          (data.validationStatus == "IN_PROGRESS" && true) ||
          (disabledFiled == false && false) ||
          (disabledFiled == "transcode" && data.transcoded == true) ||
          data.transcodingStatus == "IN_PROGRESS" ||
          data.transcodingStatus == "SUBMITTED" ||
          (disabledFiled == "novalidation" &&
            ((data.transcoded == false && data.validated == false) ||
              (data.transcoded == true &&
                data.validated == true &&
                data.published == true))) ||
          (disabledFiled == "publish_overriden" &&
            data.validated == false &&
            data.validationStatus != "OVERRIDDEN")
        ) {
        } else {
          setChecked(true);
          if (checkedIds.length == 1) {
            updatedCheckedIds.push(data.id);
            setCheckedIds(updatedCheckedIds);
          } else {
            setCheckedIds([]);
            setChecked(false);
            setDisabledFiled(false);
            setPublishActive(false);
          }
        }

        if (isChecked) {
          setAllcheck(false);
        }
      });
    }
  };

  const [disabled, setDisabled] = useState(false);
  const rotations = () => {
    setRotation(rotation + 360);
    if (!disabled) {
      setDisabled(true);


      setRotation(rotation + 360);


      setTimeout(() => {
        setDisabled(false);
      }, 5000);
    }
  };



  const isNullStatusAndNullDisabledField = (data, disabledFiled) => {
    return data?.transcodingStatus != null && disabledFiled == null;
  };

  const isNotSubmittedOrInProgress = (data, disabledFiled) => {
    return (
      (data?.transcodingStatus !== "SUBMITTED" &&
        disabledFiled === "SUBMITTED") ||
      (data?.transcodingStatus !== "IN_PROGRESS" &&
        disabledFiled === "IN_PROGRESS")
    );
  };

  const isNotError = (data, disabledFiled) => {
    return data?.transcodingStatus !== "ERROR" && disabledFiled === "ERROR";
  };

  const isNotCompletedWithNullOrError = (data, disabledFiled) => {
    return (
      data?.transcodingStatus !== "COMPLETED" &&
      disabledFiled?.transcodingStatus === "COMPLETED" &&
      (data?.validationStatus === null || data?.validationStatus === "ERROR")
    );
  };

  const isNotCompletedWithOverridden = (data, disabledFiled) => {
    return (
      data?.transcodingStatus !== "COMPLETED" &&
      disabledFiled?.transcodingStatus === "COMPLETED" &&
      data?.validationStatus === "OVERRIDDEN"
    );
  };

  const isNotCompletedWithCompleted = (data, disabledFiled) => {
    return (
      data?.transcodingStatus !== "COMPLETED" &&
      disabledFiled?.transcodingStatus === "COMPLETED" &&
      data?.validationStatus === "COMPLETED"
    );
  };

  const isPublishedConditionMet = (data, disabledFiled) => {
    const isFalseOrNull =
      data?.published !== false && disabledFiled?.published === false;

    const isTrueOrCompleted =
      data?.published !== true && disabledFiled?.published === true;

    return isFalseOrNull || isTrueOrCompleted;
  };

  const shouldDisableCheckbox = (data, disabledFiled) => {
    if (!data) return false;

    return (
      (disabledFiled && false) ||
      isNullStatusAndNullDisabledField(data, disabledFiled) ||
      isNotSubmittedOrInProgress(data, disabledFiled) ||
      isNotError(data, disabledFiled) ||
      isNotCompletedWithNullOrError(data, disabledFiled) ||
      isNotCompletedWithOverridden(data, disabledFiled) ||
      isNotCompletedWithCompleted(data, disabledFiled) ||
      isPublishedConditionMet(data, disabledFiled)
    );
  };



  const setPage = (e) => {
    if (e.target.value > 0 && e.target.value <= totalPages) {
      setPageJump(e.target.value);
    }
  };



 

  return (
    <div className="content-body">
      <div className="dashboard-content">
        <div className="top-content">
          <div className="left-side">
            <div className="breadcrumbs bread-action">
              <ul>
                <li>
                  <a href="/asset">Asset Management</a>
                </li>
                <li>
                  <a onClick={navigateBack}>{AssetPartnerDetail?.name}</a>
                </li>
                <li className="active">Actions</li>
              </ul>
            </div>
          </div>
          <div className="right-side">
            <div
              className="search-box"
              style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #ccc",
                borderRadius: "6px",
                padding: "3px 12px",
                color: '#fff',

                backgroundColor: "#fff",
              }}
            >
              <input
                className="search-input"
                type="text"
                placeholder="Search...."
                value={searchQuerys}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={handleKeyPress}
                style={{
                  flex: 1,
                  border: "none",
                  outline: "none",
                  fontSize: "16px",
                  padding: "0px 8px",
                  lineHeight: "20px",
                  textAlign: "left",
                  marginTop: "6px",
                  backgroundColor: "transparent",
                }}
              />
              <img
                src={Searchicon}
                alt="Search"
                className="search-icon"
                style={{
                  height: "20px",
                  width: "20px",
                  marginLeft: "8px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>

        </div>

        <div
          className="action-category"
          style={{
            marginTop: 30,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 20
          }}
        >

          <div style={{ display: "flex", alignItems: "center" }}>
            {permission && (
              <ul style={{ display: "flex", margin: 0, padding: 0 }}>
                <li
                  className={
                    transcodeActive && checkedIds.length > 0 ? "active" : "disable"
                  }
                >
                  <div className="dropdown action-dropdown buttons-box">
                    <a
                      className="border-0"
                      onClick={() =>
                        transcodeActive && checkedIds.length > 0
                          ? AssetActions("TRANSCODE", true)
                          : null
                      }
                      style={{
                        fontSize: "16px",
                        padding: "15px 20px",
                      }}
                    >

{isProcessing && currentAction === "TRANSCODE" ? (
              <span>Transcoding content to playout... Please wait.</span>
            ) : (
              <>
                Transcode
                <span className="ml-2">&#9660;</span>
              </>
            )}
                    </a>
                    {transcodeActive && checkedIds.length > 0 && (
                      <div className="dropdown-content w-auto">
                        <button
                          className="transoption"
                          onClick={() =>
                            transcodeActive && checkedIds.length > 0
                              ? AssetActions("TRANSCODE", false)
                              : null
                          }
                          style={{
                            fontSize: "14px",
                            padding: "8px 16px",
                          }}
                        >
                          Run Media Encoder
                        </button>
                        <button
                          className="transoption"
                          onClick={() =>
                            transcodeActive && checkedIds.length > 0
                              ? AssetActions("TRANSCODE", true)
                              : null
                          }
                          style={{
                            fontSize: "14px",
                            padding: "8px 16px",
                          }}
                        >
                          AWS MediaConvert
                        </button>
                      </div>
                    )}
                  </div>
                </li>
                <li
                  className={
                    validateActive && checkedIds.length > 0 ? "active" : "disable"
                  }
                >
                  <div className="dropdown action-dropdown buttons-box">
                    <a
                      onClick={() =>
                        validateActive && checkedIds.length > 0
                          ? AssetActions("VALIDATE")
                          : null
                      }
                      style={{
                        fontSize: "16px",
                        padding: "15px 20px",
                      }}
                    >
                      {isProcessing && currentAction === "VALIDATE" ? (
              <span>Validating content to playout... Please wait.</span>
            ) : (
              "Validate"
            )}
                    </a>
                  </div>
                </li>
                <li
                  className={
                    publishActive && checkedIds.length > 0 ? "active" : "disable"
                  }
                  style={{ marginRight: 20 }}
                >
                  <div className="dropdown action-dropdown buttons-box">
                    <a
                      className="border-0"
                      style={{
                        fontSize: "16px",
                        padding: "15px 20px",
                      }}
                    >
                       {isProcessing && currentAction === "PUBLISH" ? (
              <span>Publishing content to Playout... Please wait.</span>
            ) : (
              <>
                Publish
                <span className="ml-2">&#9660;</span>
              </>
            )}
                    </a>
                    {publishActive && checkedIds.length > 0 && (
                      <div className="dropdown-content w-auto">
                        <button
                          className="transoption"
                          onClick={() =>
                            publishActive && checkedIds.length > 0
                              ? AssetActions("RUNN_MEDIA")
                              : null
                          }
                          style={{
                            fontSize: "14px",
                            padding: "8px 16px",
                          }}
                        >
                          Runn Playout
                        </button>
                        <button
                          className="transoption"
                          onClick={() =>
                            publishActive && checkedIds.length > 0
                              ? AssetActions("AWS")
                              : null
                          }
                          style={{
                            fontSize: "14px",
                            padding: "8px 16px",
                          }}
                        >
                          AWS Mediatailor
                        </button>
                      </div>
                    )}
                  </div>
                </li>
              </ul>
            )}

            {/* Global Loader */}
            {isProcessing && (
  <div className="loader-container">
    <div className="loader"></div>
    <p>{currentAction}</p>
  </div>
)}
            {/* {isProcessing && (
  <div className="loader-container">
    <div className="loader"></div>
   
    <p>{`Processing ${currentAction} to playout....Please wait.`}</p>
  </div>
)} */}
          </div>



          <div >
            <button
              className={`refresh-button m-2 `}
              onClick={() => {
                fetchData();
                rotations();
              }}
              disabled={disabled}
            >
              <img
                className={`${rotation !== 0 ? "rotate" : ""}`}
                style={{ transform: `rotate(${rotation}deg)` }}
                src={refresh}
                alt="refresh"
              />
            </button>
            {/* <button
              className={`refresh-button m-2 border-1`}
              onClick={() => {
                fetchData();
                rotations();
              }}
              disabled={disabled}
            >
              <img
                className={`${rotation !== 0 ? "rotate" : ""}`}
                // style={{ transform: `rotate(${rotation}deg)` }}
                src={Download}
                alt="Download"
              />
            </button> */}
            <button
              className={`refresh-button m-2 border-1`}
              onClick={() => {
                fetchData();
                rotations();
              }}
              disabled={disabled}
            >
              <img
                className={`${rotation !== 0 ? "rotate" : ""}`}
                // style={{ transform: `rotate(${rotation}deg)` }}
                src={Delete}
                alt="Delete"
              />
            </button>
          </div>
        </div>


        <div className="table-checkbox">
          <div className="table">
            <table className="striped-table"
              style={{
                borderCollapse: "separate",
                borderSpacing: "0 8px",
                width: "100%",
              }}>
              <thead >



                <tr>
                  {permission && (
                    <th style={{ textAlign: "center", fontSize: "16px", paddingRight: 25 }}>
                      <input
                        type="checkbox"
                        id="selectAllCheckbox"
                        checked={isChecked}
                        onChange={() => handleSelectAllClick()}

                      />
                      <label
                        className={isChecked ? "check" : null}
                        htmlFor="selectAllCheckbox"
                      >
                        &nbsp;
                      </label>
                    </th>
                  )}
                  <th style={{ textAlign: "left", fontSize: "14px", paddingLeft: 22, color: '#BFBFBF' }}>
                    {selectedType} Name
                    <span
                      className="filter-icon"
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSort("filename")}
                    >
                      {getSortIcon("filename")}
                    </span>
                  </th>

                  <th
                    style={{
                      textAlign: "right",
                      fontSize: "14px",
                      color: "#BFBFBF",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={toggleDropdown} // Toggle dropdown visibility
                    >
                      <span style={{ color: isDropdownOpen ? "red" : "#BFBFBF" }}>Type</span>
                      <img
                        src={downarrow} // Use your white arrow icon here
                        alt="Down Arrow"
                        style={{
                          marginLeft: "8px",
                          width: "12px",
                          height: "12px",
                          transform: isDropdownOpen ? "rotate(180deg)" : "rotate(0deg)",
                          transition: "transform 0.3s ease",
                        }}
                      />
                    </div>

                    {isDropdownOpen && (
                      <div
                        style={{
                          position: "absolute",
                          top: "100%",
                          right: "0",
                          background: "#ffffff",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          zIndex: 1000,
                          width: "150px", // Dropdown width
                        }}
                      >
                        {typeOptions.length === 0 ? (
                          <div
                            style={{
                              padding: "8px 12px",
                              textAlign: "center", // Center-align text
                              fontSize: "14px",
                              color: "#333",
                            }}
                          >
                            No options available
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              padding: "8px 0", // Add spacing for the dropdown options
                              fontSize: "14px",
                              color: "#333",
                              background: "transparent",
                            }}
                          >
                            {typeOptions.map((option, index) => (
                              <div
                                key={index}
                                onClick={() => {
                                  setSelectedType(option);
                                  setIsDropdownOpen(false); // Close dropdown on selection
                                }}
                                style={{
                                  padding: "8px 12px",
                                  textAlign: "center", // Center-align text
                                  cursor: "pointer",
                                  transition: "background-color 0.3s ease, color 0.3s ease",
                                }}
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.backgroundColor = "#f5f5f5";
                                  e.currentTarget.style.color = "red"; // Hover red text
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.backgroundColor = "transparent";
                                  e.currentTarget.style.color = "#333"; // Reset text color
                                }}
                              >
                                {option}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                  </th>








                  <th style={{ textAlign: "right", fontSize: "14px", color: '#BFBFBF' }}>
                    Duration
                    <span
                      className="filter-icon"
                      style={{
                        display: "inline-flex",
                        alignItems: "right",
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSort("duration")}
                    >
                      {getSortIcon("duration")}
                    </span>
                    <br /><span style={{ fontWeight: 100, fontSize: 14, color: '#BFBFBF' }}> hh:mm:ss</span>
                  </th>
                  <th style={{ textAlign: "right", fontSize: "14px", color: '#BFBFBF' }}>
                    Date Uploaded
                    <span
                      className="filter-icon"
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSort("dateUploaded")}
                    >
                      {getSortIcon("dateUploaded")}
                    </span>
                    <br /><span style={{ fontWeight: 100, fontSize: 14, textAlign: "center", color: '#BFBFBF' }}>  dd/mm/yy</span>
                  </th>
                  <th style={{ textAlign: "center", fontSize: "14px", color: '#BFBFBF' }}>
                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                      <span>Transcode</span>
                      <span
                        // className="filter-icon"
                        style={{
                          display: "inline-flex",
                          alignItems: "right",
                          marginLeft: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleSort("dateTranscoded")}
                      >
                        {getSortIcon("dateTranscoded")}
                      </span>
                    </div>
                  </th>
                  <th style={{ textAlign: "center", fontSize: "14px", color: '#BFBFBF' }}>
                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                      <span>Validate</span>
                      <span
                        // className="filter-icon"
                        style={{
                          display: "inline-flex",
                          marginLeft: 7,
                          cursor: "pointer",
                        }}
                        onClick={() => handleSort("dateValidated")}
                      >
                        {getSortIcon("dateValidated")}
                      </span>
                    </div>
                  </th>




                  <th >
                    <div
                      style={{ fontSize: 14, color: '#BFBFBF' }}
                    >
                      <span>Publish</span>
                      <span
                        // className="filter-icon"
                        onClick={() => handleSort("datePublished")}
                        style={{ cursor: "pointer", marginLeft: 7 }}
                      >
                        {getSortIcon("datePublished")}
                      </span>
                    </div>
                  </th>
                </tr>
              </thead>



              <tbody >
                {actionDetail.map((data, index) => (
                  <tr
                    key={index}
                    className={`${index % 2 === 0 ? 'even' : 'odd'} hover:bg-gray-200 `}
                  
                  >
                    {permission && (
                      <td style={{ paddingRight: '20px', textAlign: 'left', backgroundColor: '#121212' }}>
                        <input
                          id={data.id}
                          type="checkbox"
                          onChange={(e) =>
                            data?.active !== false ? handleCheckboxChange(e, data, index) : null
                          }
                          checked={checkedIds.includes(data.id)}
                          disabled={shouldDisableCheckbox(data, disabledFiled)}
                        />
                        <label htmlFor={data.id}>&nbsp;</label>
                      </td>
                    )}

                    <td
                      style={{
                        paddingRight: '20px',
                        textAlign: 'left',
                        fontSize: 17,
                        maxWidth: '250px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        position: 'relative', 
                        backgroundColor: '#121212'
                      }}
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title={data?.metadata?.title || data.filename || 'No title'} // Bootstrap tooltip
                    >
                      {data?.metadata?.title || data.filename || 'No title'}

                      {/* Custom Tooltip (For more control over styles) */}
                      <div
                        style={{
                          position: 'absolute',
                          backgroundColor: '#121212',
                          color: 'white',
                          padding: '5px',
                          borderRadius: '4px',
                          top: '100%',
                          left: '0',
                          zIndex: 10,
                          whiteSpace: 'nowrap',
                          width: 'auto',
                          maxWidth: '300px',
                          wordWrap: 'break-word',
                          visibility: 'hidden', // Initially hidden
                        }}
                        className="tooltip-content" // Add a specific class for the tooltip
                      >
                        {data?.metadata?.title || data.filename || 'No title'}
                      </div>
                    </td>

                    <td style={{ paddingRight: '20px', textAlign: 'right', fontSize: 16, backgroundColor: '#121212' }}>
                      {data.videoType ? data.videoType : selectedType}
                    </td>

                    <td style={{ paddingRight: '20px', textAlign: 'right', fontSize: 18, backgroundColor: '#121212' }}>
                      {data.duration != null
                        ? convertTimestampToTime(data.duration)
                        : data.metadata != null
                          ? data.metadata.duration != null
                            ? data.metadata.duration
                            : 'N/A'
                          : 'N/A'}
                    </td>

                    <td style={{ paddingRight: '20px', textAlign: 'right', fontSize: 17, backgroundColor: '#121212' }}>
                      {DateStamp(data.dateUploaded)}
                    </td>

                    <td style={{ backgroundColor: '#121212' }}>
                      <div

                        data-tip={
                          data?.jobPercentComplete == null || data.transcoded === true
                            ? ''
                            : data?.jobPercentComplete + '%'
                        }
                        className={data?.transcoded === true ? 'complete' : actionStatus(data?.transcodingStatus)}
                      >
                        {data.transcoded !== true ? (
                          errorstatus(data?.transcodingStatus)
                        ) : (
                          <>
                            <img src={donecheck} style={{ marginRight: "8px" }} />
                            {DateStamp(data.dateTranscoded)}
                          </>
                        )}
                      </div>
                    </td>

                    <td style={{ textAlign: 'right', marginLeft: '120px', backgroundColor: '#121212' }}>
                      <div
                        onClick={data?.active !== false ? () => validationation(data) : null}
                        className={
                          data?.transcoded === true
                            ? data?.validated === true && data?.validationStatus === 'COMPLETED'
                              ? 'complete'
                              : actionStatus(data?.validationStatus)
                            : 'pending'
                        }
                      >
                        {data?.validated === true && data?.validationStatus === 'COMPLETED' ? (
                          <>
                            <img src={donecheck} style={{ marginRight: "8px" }} />
                            {DateStamp(data?.dateValidated)}
                          </>
                        ) : (
                          errorstatus(data?.validationStatus)
                        )}
                      </div>
                    </td>

                    <td style={{ textAlign: 'right', backgroundColor: '#121212' }}>
                      <div className={data?.published === true ? 'complete' : 'pending'}>
                        {data.datePublished === null ? (
                          blankValue
                        ) : (
                          <>
                            <img src={donecheck} style={{ marginRight: "8px" }} />
                            {DateStamp(data.datePublished)}
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>




            </table>
          </div>

          {actionDetail.length !== 0 && (
            <div className="table-pagination">
              <div className="pagination-count">
                <div className="count">
                  Page: {currentPage} of {totalPages}
                </div>
                <div className="count">
                  {`${Math.min(
                    (currentPage - 1) * itemsPerPage + 1,
                    actionData.totalElements
                  )}-${Math.min(
                    currentPage * itemsPerPage,
                    actionData.totalElements
                  )} of ${actionData.totalElements}`}
                </div>
                <div className="pagination-arrow">
                  {/* Previous Page Arrow */}
                  <a
                    className={`prev ${currentPage === 1 ? "disabled" : ""}`}
                    onClick={() => handlePageChange("prev")}
                    disabled={currentPage === 1}
                  >
                    <img src={leftArrow} alt="Previous" />
                  </a>

                  {/* Input to jump to specific page */}
                  <div className="text-center">
                    <form onSubmit={(e) => e.preventDefault()}>
                      <input
                        type="number"
                        value={inputPage}
                        onChange={handleInputChange}
                        onKeyDown={handlePageKeyPress}
                        min={1}
                        max={totalPages}
                        className="no-arrows" // Apply CSS class to remove arrows
                      />
                    </form>
                  </div>

                  {/* Next Page Arrow */}
                  <a
                    className={`next ${currentPage === totalPages ? "disabled" : ""}`}
                    onClick={() => handlePageChange("next")}
                    disabled={currentPage === totalPages}
                  >
                    <img src={rightArrow} alt="Next" />
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>

      </div>

      <ToastContainer />
      {validatepopup && (
        <Validatepopup
          handleClosePopup={() => setvalidatepopup(false)}
          validateinfo={validatedata}
          assetsingledata={assetSignledata}
          permission={permission}
        />
      )}
    </div>
  );
}
