import React, { useEffect, useState } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  Marker
} from "react-simple-maps";
import { geoCentroid, geoPath } from "d3-geo";
import { useSelector } from "react-redux";
import india from './topojsons/india.json';
import "./MapChart.scss";
import { formatNumber } from "../../../service/commonFunc";

const MapChart = ({ setTooltipContent, setStateName, setShowDistrict, setzoom }) => {
  const state = useSelector((state) => state);
  const MapDatastate = state?.MapData?.data;
  const [MapStateData, setMapStateData] = useState([{ name: 'Bihar', percentage: 0.0 }]);
  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    if (MapDatastate?.data?.stateWiseActiveUsers !== undefined && MapDatastate?.data?.stateWiseActiveUsers !== null) {
      setMapStateData(MapDatastate?.data?.stateWiseActiveUsers);
    }

    const formattedData = [];
    for (const [name, percentage] of Object.entries(MapStateData)) {
      formattedData.push({ name, percentage: (percentage / MapDatastate?.data?.maxActiveUsers), activeUser: percentage });
    }
    setStateData(formattedData);
  }, [state?.MapData?.data, MapStateData]);

  const getColorByPercentage = (percentage) => {
    // Define colors for different percentage ranges
    const colors = {
      veryLow: "#E7D4F6",
      low: "#6A1EA8",
      medium: "#B67DE4",
      high: "#8526D2",
      veryHigh: "#280B3F"
    };

    if (percentage <= 0.2) return colors.veryLow;  
    if (percentage > 0.2 && percentage <= 0.4) return colors.low;    
    if (percentage > 0.4 && percentage <= 0.6) return colors.medium; 
    if (percentage > 0.6 && percentage <= 0.8) return colors.high;   
    if (percentage > 0.8) return colors.veryHigh;     
  };

  const handleMouseEnter = (geo) => {
    const { ST_NM } = geo.properties;
    setTooltipContent(ST_NM);
  };

  const handleMouseLeave = () => {
    setTooltipContent("");
  };

  const handleMouseMove = (e) => {
    const tooltip = document.querySelector(".tooltip");
    if (tooltip) {
      tooltip.style.left = e.pageX + "px";
      tooltip.style.top = e.pageY + "px";
    }
  };

  return (
    <>
      <ComposableMap
        data-tip=""
        projection="geoMercator"
        width={150}
        height={150}
        projectionConfig={{ scale: 220 }}
        onMouseMove={handleMouseMove}
      >
        <ZoomableGroup zoom={setzoom} center={[80, 22]}>
          <Geographies geography={india}>
            {({ geographies }) =>
              geographies.map(geo => {
                const stateInfo = stateData.find(stateObj => stateObj.name === geo.properties.ST_NM);
                const percentage = stateInfo ? stateInfo.percentage : 0;
                const fill = getColorByPercentage(percentage);
                const perce = stateInfo ? stateInfo.activeUser : 0;
                const path = geoPath().projection(geo.projection);
                const centroid = geoCentroid(geo);
                const [x, y] = path.centroid(geo);

                return (
                  <g key={geo.rsmKey}>
                    <Geography
                      geography={geo}
                      onMouseEnter={() => handleMouseEnter(geo)}
                      onMouseLeave={handleMouseLeave}
                      style={{
                        default: {
                          fill,
                          stroke: "#000",
                          strokeWidth: 0.05,
                          outline: "none"
                        },
                        hover: {
                          fill,
                          stroke: "#fff",
                          strokeWidth: 0.6,
                          outline: "none"
                        },
                        pressed: {
                          fill,
                          stroke: "#5F0F13",
                          strokeWidth: 0.1,
                          outline: "none"
                        },
                      }}
                    />
                    <Marker coordinates={[x, y]}>
                      <text
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{
                          fontFamily: "Arial, sans-serif",
                          fontSize: 3,
                          fill: percentage === Math.max(...stateData.map(s => s.percentage)) ? "#fff" : "#000"
                        }}
                      >
                        {perce === 0 ? null : formatNumber(perce)}
                      </text>
                    </Marker>
                  </g>
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </>
  );
};

export default MapChart;
