
// import React, { useEffect, useState, useRef } from "react";
// import "./vieweship.scss";
// import ActiveUser from "./active-user";
// import AccordionBlock from "./accordian/AccordionBlock";
// import AccordionFilter from "./accordian/AccordionFilter";
// import { useDispatch, useSelector } from "react-redux";
// import { ChannelDataAction, MapDataAction } from "../../Redux/slices";
// import { ViewershipMap, ViewershipTablehead } from "../../api/api";
// import { TimeConverter, capitalizeFirstLetter, formatNumber, isLogin } from "../../service/commonFunc";
// import GraphIcon from '../../assets/svg/viewership/graph.svg';
// import TableIcon from '../../assets/svg/viewership/table.svg'

// import sort from "../../assets/images/sort.png";
// import desc from "../../assets/images/desc.png";
// import filter_alt from "../../assets/images/sortarrow.png";
// import { Line } from 'react-chartjs-2';
// import 'chart.js/auto';

// function Viewership() {
//   const [showLoader, setshowLoader] = useState(true);
//   const [Duration, setDuration] = useState("Last 24 Hours");
//   const [TableHead, setTableHead] = useState([]);
//   const [chartData, setChartData] = useState({
//     labels: [],
//     datasets: [
//       {
//         label: 'Number of Active Users',
//         data: [],
//         fill: false,
//         borderColor: '#ffffff', 
//         tension: 0.2,
//         borderWidth: 4,
//         pointRadius: 4, 
//         pointBackgroundColor: '#0f520c', 
//         pointBorderColor: '#ffffff', 
//         pointBorderWidth: 2, 
//         pointHoverRadius: 6, 
//         hidden: false, 
//       },
//       {
//         label: 'Total Watch Hours',
//         data: [],
//         fill: false,
//         tension: 0.2,
//         borderColor: '#FFFF00',
//         borderWidth: 4,
//         pointRadius: 4, 
//         pointBackgroundColor: '#000000', 
//         pointBorderColor: '#ffffff', 
//         pointBorderWidth: 2, 
//         pointHoverRadius: 6, 
//         hidden: false, 
//       },
//       {
//         label: 'Total Ad Impression',
//         data: [],
//         fill: false,
//         borderColor: '#A52A2A',     
//         tension: 0.2,
//         borderWidth: 4,
//         pointRadius: 4, 
//         pointBackgroundColor: '#000000', 
//         pointBorderColor: '#ffffff', 
//         pointBorderWidth: 2,       
//         pointHoverRadius: 6, 
//         hidden: false, 
//       },
//     ],
//   });
//   const [graphicalView, setGraphicalView] = useState(false);
//   const [filterVal, setFilterVal] = useState({
//     viewType: "CHANNEL",
//     duration: "DAY",
//   });

//   const isActive = true;

//   const {
//     appThemeReducer: { appTheme },
//     adminModeReducer: { adminMode },
//   } = useSelector((state) => state);



//   const [xAxis, setXAxis] = useState([]);
//   const [yAxis, setYAxis] = useState([]);
//   const [yAxis2, setYAxis2] = useState([]);
//   const [yAxis3, setYAxis3] = useState([]);
//   const [openIndex, setOpenIndex] = useState(null);

//   const [chartOptions, setChartOptions] = useState({
//     plugins: {
//       tooltip: {
//         enabled: true,
//         mode: 'index',
//         intersect: false,
//         callbacks: {
//           label: function(context) {
//             let label = context.dataset.label || '';
//             if (label) {
//               label += ': ';
//             }
//             if (context.parsed.y !== null) {
//               label += context.parsed.y;
//             }
//             return label;
//           }
//         }
//       },
//       legend: {
//         display: true,

//       },
//     },
//     hover: {
//       mode: 'nearest',
//       intersect: true
//     },
//     scales: {
//       x: {
//         display: true,
//         title: {
//           display: true,
//           text: 'Program Name',
//         },
//         grid: {
//           lineWidth: 30,
//           color: appTheme === "dark" ? '#99999914' : '#FCFCFD', 
//         },
//         padding: 5,
//         autoSkip: false,
//         maxRotation: 0,
//         minRotation: 0,
//       },
//       y: {
//         display: true,
//         title: {
//           display: true,
//           text: 'Information',
//         },
//         border: { dash: [4, 4] },
//         grid: {
//           tickBorderDash: [15, 10],
//         },
//         beginAtZero: true,
//         ticks: {
//           padding: 20,
//         },
//       }
//     },
//     layout: {
//       padding: {
//         top: 50,
//         bottom: 50,
//         left: 0,
//         right: 0
//       }
//     },
//     maintainAspectRatio: false,
//   });


//   const [sortOrders, setSortOrders] = useState({["viewKey"]: "asc"});
//   const [TotalHeader, setTotalHeader] = useState({
//     "viewType": "HEADER",
//     "viewKey": "Average",
//     "numberOfActiveUsers": 0,
//     "totalWatchHours": 0,
//     "averageWatchTimePerUser": 0,
//     "averageWatchTimePerSession": 0,
//     "totalAdImpression": 0
//   });

//   const dispatch = useDispatch();

//   const changeGraphView = (val) => {

//     const xdata = [];
//     const ydata = [];
//     const ydata2 = [];
//     const ydata3 = [];

//     TableHead.forEach((data) => {
//       // xdata.push(data.viewKey);
//       xdata.push(data.totalWatchHours);
//       ydata.push(data.numberOfActiveUsers);
//       ydata2.push(data.totalWatchHours);
//       ydata3.push(data.totalAdImpression);
//     });

//     setXAxis(xdata);
//     setYAxis(ydata);
//     setYAxis2(ydata2);
//     setYAxis3(ydata3);

//     setGraphicalView(val);
//   };

//   // Gradient Refs
//   const gradient1Ref = useRef(null);
//   const gradient2Ref = useRef(null);
//   const gradient3Ref = useRef(null);

//   const chartRef = useRef(null); 


//   const gradientColors1 = ['#F7E9D3 ','#92F134 ', '#138800', '#008826','#5AF134','#DEF8D5']; 
//   const gradientColors2 = ['#F7E2D3', '#F18F34', '#885200','#884900','#F19A34','#F8ECD5']; 
//   const gradientColors3 = ['#FFA07A', '#FF8C00', '#8B4513']; 

//   // Function to create a consistent horizontal gradient
//   const createFixedGradient = (ctx, chartArea, colors) => {
//     const gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0); 
//     colors.forEach((color, index) => {
//       gradient.addColorStop(index / (colors.length - 1), color);
//     });
//     return gradient;
//   };

//   useEffect(() => {
//     isLogin();
//   }, []);

//   // Initialize gradients once after the chart is rendered
//   useEffect(() => {
//     const chart = chartRef.current;
//     if (!chart) return;

//     const handle = () => {
//       const { ctx, chartArea } = chart;
//       if (!chartArea) return;

//       if (!gradient1Ref.current) {
//         gradient1Ref.current = createFixedGradient(ctx, chartArea, gradientColors1);
//       }
//       if (!gradient2Ref.current) {
//         gradient2Ref.current = createFixedGradient(ctx, chartArea, gradientColors2);
//       }
//       if (!gradient3Ref.current) {
//         gradient3Ref.current = createFixedGradient(ctx, chartArea, gradientColors3);
//       }

//       // Update chart data with gradients
//       setChartData(prevData => ({
//         ...prevData,
//         labels: xAxis,
//         datasets: [
//           {
//             ...prevData.datasets[0],
//             data: yAxis,
//             borderColor: gradient1Ref.current,
//           },
//           {
//             ...prevData.datasets[1],
//             data: yAxis2,
//             borderColor: gradient2Ref.current,
//           },
//           {
//             ...prevData.datasets[2],
//             data: yAxis3,
//             borderColor: gradient3Ref.current,
//           },
//         ],
//       }));

//       chart.update();
//     };

//     // Call handle after the chart is rendered
//     handle();

//     // Re-initialize gradients on window resize to maintain gradients
//     const handleResize = () => {
//       handle();
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, [xAxis, yAxis, yAxis2, yAxis3]); // Dependencies based on data changes

//   const toggleAccordion = (index) => {
//     if (openIndex === index) {
//       setOpenIndex(null);
//     } else {
//       setOpenIndex(index);
//     }
//   };

//   const sortOrderEvent = (columnValue) => {
//     const currentSortOrder = sortOrders[columnValue] || "asc";
//     const newSortOrder = currentSortOrder === "desc" ? "asc" : "desc";

//     setSortOrders({ [columnValue]: newSortOrder });
//     const headeFilter = {
//       sort: newSortOrder,
//       key: columnValue,
//     }

//     filterChangeData(filterVal.viewType, filterVal.duration, headeFilter)
//   };

//   const mapRenderData = async (key) => {
//     const ViewershipMapData = {
//       channelName: key,
//       allChannels: true
//     };

//     const MapData = await ViewershipMap(ViewershipMapData);

//     if (MapData?.data) {
//       dispatch(MapDataAction(MapData?.data));
//     }
//   };

//   const filterChangeData = async (view, duration, headeFilter) => {
//     const ViewershipTableheadData = {
//       viewType: view.toUpperCase(),
//       duration: duration.toUpperCase(),
//       sortOrder: headeFilter.sort,
//       sortBy: headeFilter.key
//     };

//     const data = await ViewershipTablehead(ViewershipTableheadData, setshowLoader);
//     // changeGraphView()
//     // console.log("dddd",JSON.stringify(data))
//     if (duration === "Month") {
//       setDuration("Last 30 Days");
//     } else if (duration === "Week") {
//       setDuration("Last 7 Days");
//     }
//     else if (duration === "Year") {
//       setDuration("Last 365 Days");
//     } else {
//       setDuration("Last 24 Hours");
//     }

//     setTableHead(data?.data.data.rows);
//     mapRenderData(data?.data.data?.header.viewKey);
//     setTotalHeader(data?.data.data?.header);

//     if (filterVal.duration === duration.toUpperCase()) {
//       setOpenIndex(null);
//       dispatch(ChannelDataAction(data?.data.data?.header));
//     }

//     let filterObj = {
//       viewType: view.toUpperCase(),
//       duration: duration.toUpperCase(),
//     };
//     setFilterVal(filterObj);

//     // Re-initialize gradients if necessary
//     if (chartRef.current) {
//       const chart = chartRef.current;
//       const { ctx, chartArea } = chart;
//       if (chartArea) {
//         if (!gradient1Ref.current) {
//           gradient1Ref.current = createFixedGradient(ctx, chartArea, gradientColors1);
//         }
//         if (!gradient2Ref.current) {
//           gradient2Ref.current = createFixedGradient(ctx, chartArea, gradientColors2);
//         }
//         if (!gradient3Ref.current) {
//           gradient3Ref.current = createFixedGradient(ctx, chartArea, gradientColors3);
//         }

//         setChartData(prevData => ({
//           ...prevData,
//           labels: xAxis,
//           datasets: [
//             {
//               ...prevData.datasets[0],
//               data: yAxis,
//               borderColor: gradient1Ref.current,
//             },
//             {
//               ...prevData.datasets[1],
//               data: yAxis2,
//               borderColor: gradient2Ref.current,
//             },
//             {
//               ...prevData.datasets[2],
//               data: yAxis3,
//               borderColor: gradient3Ref.current,
//             },
//           ],
//         }));

//         chart.update();
//       }
//     }


//     //added for update
//     const xdata = [];
//     const ydata = [];
//     const ydata2 = [];
//     const ydata3 = [];

//     TableHead.forEach((data) => {
//       // xdata.push(data.viewKey);
//       xdata.push(data.totalWatchHours);
//       ydata.push(data.numberOfActiveUsers);
//       ydata2.push(data.totalWatchHours);
//       ydata3.push(data.totalAdImpression);
//     });

//     setXAxis(xdata);
//     setYAxis(ydata);
//     setYAxis2(ydata2);
//     setYAxis3(ydata3);
//   };

//   return (
//     <>
//       <div className={`content-body ${(adminMode || appTheme === "dark") ? "dark" : "light"}`}>
//         <div className={`deshboard-content ${(adminMode || appTheme === "dark") ? "dark" : "light"}`}>
//           <div className="title">
//             <h2 style={{marginTop:-30}}>Viewership</h2>
//           </div>



//           <AccordionFilter 
//             setSortOrders={setSortOrders} 
//             Durations={Duration} 
//             handleChange={filterChangeData} 
//             handleGraphicalView={changeGraphView}
//           />
//           <div className="Duration-time">
//             {/* Optional: Add additional time display here */}
//           </div>
//           <div className="channel-container">
//             <div className="channel-table">
//               <div className="channel-table-header">
//                 <div className="table-row head" >
//                   <div className="table-header-col name"  style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }}>
//                     {capitalizeFirstLetter(filterVal.viewType)}  Name
//                     <a className='filter-icon mx-2' onClick={() => sortOrderEvent("viewKey")}>
//                       {sortOrders["viewKey"] === "desc" && <img src={desc} alt="Descending" />}
//                       {sortOrders["viewKey"] === "asc" && <img src={filter_alt} alt="Ascending" />}
//                       {!sortOrders["viewKey"] && <img src={sort} alt="No Sorting" />}
//                     </a>
//                   </div>
//                   <div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }}>
//                     Active Users
//                     <a className='filter-icon mx-2' onClick={() => sortOrderEvent("numberOfActiveUsers")}>
//                       {sortOrders["numberOfActiveUsers"] === "asc" && <img src={filter_alt} alt="Ascending" />}
//                       {sortOrders["numberOfActiveUsers"] === "desc" && <img src={desc} alt="Descending" />}
//                       {!sortOrders["numberOfActiveUsers"] && <img src={sort} alt="No Sorting" />}
//                     </a>
//                   </div>
//                   <div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }}>
//                     Total Watch Hours
//                     <a className='filter-icon mx-2' onClick={() => sortOrderEvent("totalWatchHours")}>
//                       {sortOrders["totalWatchHours"] === "asc" && <img src={filter_alt} alt="Ascending" />}
//                       {sortOrders["totalWatchHours"] === "desc" && <img src={desc} alt="Descending" />}
//                       {!sortOrders["totalWatchHours"] && <img src={sort} alt="No Sorting" />}
//                     </a>
//                   </div>
//                   <div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }}>
//                     Avg - Watch Time
//                     <a className='filter-icon mx-2' onClick={() => sortOrderEvent("averageWatchTimePerUser")}>
//                       {sortOrders["averageWatchTimePerUser"] === "asc" && <img src={filter_alt} alt="Ascending" />}
//                       {sortOrders["averageWatchTimePerUser"] === "desc" && <img src={desc} alt="Descending" />}
//                       {!sortOrders["averageWatchTimePerUser"] && <img src={sort} alt="No Sorting" />}
//                     </a> Per User
//                     <br />
//                     <small>(HH:MM:SS)</small>
//                   </div>
//                   <div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }}>
//                     Avg - Watch Time
//                     <a className='filter-icon mx-2' onClick={() => sortOrderEvent("averageWatchTimePerSession")}>
//                       {sortOrders["averageWatchTimePerSession"] === "asc" && <img src={filter_alt} alt="Ascending" />}
//                       {sortOrders["averageWatchTimePerSession"] === "desc" && <img src={desc} alt="Descending" />}
//                       {!sortOrders["averageWatchTimePerSession"] && <img src={sort} alt="No Sorting" />}
//                     </a> Per Session
//                     <br />
//                     <small>(HH:MM:SS)</small>
//                   </div>
//                   {/* <div className="table-header-col" onClick={() => sortOrderEvent("totalAdImpression")}>Total Ad Impression
//                     <a className='filter-icon mx-2' onClick={() => sortOrderEvent("totalAdImpression")}>
//                       <img src={filter_alt} alt="Filter" />
//                     </a>
//                   </div> */}
//                 </div>
//               </div>

//               {/* { showLoader &&<Loader /> } */}

//               <div
//                 onClick={() => filterChangeData(filterVal.viewType, filterVal.duration, { sort: "desc", key: "viewKey" })}
//                 className="channel-table-header"  
//               >
//                 <div className="table-row" style={{ cursor: "pointer" }}>
//                   <div className="table-header-col name" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }} >
//                     All {capitalizeFirstLetter(filterVal.viewType)}s
//                   </div>
//                   <div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }}>{formatNumber(TotalHeader?.numberOfActiveUsers)}</div>
//                   <div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }}>{formatNumber(TotalHeader?.totalWatchHours?.toFixed(2))} Hrs</div>
//                   <div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }}>
//                     {TimeConverter(TotalHeader?.averageWatchTimePerUser)}
//                     <br />
//                   </div>
//                   <div className="table-header-col" style={{ color: appTheme === "dark" ? '#E9E9E9' : 'black' }}>
//                     {TimeConverter(TotalHeader?.averageWatchTimePerSession)}
//                   </div>
//                   {/* <div className="table-header-col">{TotalHeader?.totalAdImpression}</div> */}
//                 </div>
//               </div>




//               <div className="channel-table-body" style={{width:'100%'}}>
//                 {graphicalView === false ? (
//                   <div className="channel-accordion">
//                     {TableHead &&
//                       TableHead?.map((data, index) => (
//                         <AccordionBlock
//                           key={index}
//                           title={data}
//                           closeaccodian={() => filterChangeData(filterVal.viewType, filterVal.duration)}
//                           isOpen={index === openIndex}
//                           onToggle={() => toggleAccordion(index)}
//                           filter={filterVal}

//                         />
//                       ))}
//                   </div>
//                 ) : (
//                   <div className="line-graph" style={{ height: '665px',marginTop:-50, }}>
//                     <Line ref={chartRef} data={chartData} options={chartOptions} />
//                   </div>
//                 )}
//               </div>



//             </div>
//             <div className="active-user-section">
//               <ActiveUser />
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Viewership;

import React, { useEffect, useState, useRef } from "react";
import "./vieweship.scss";
import ActiveUser from "./active-user";
import AccordionBlock from "./accordian/AccordionBlock";
import AccordionFilter from "./accordian/AccordionFilter";
import { useDispatch, useSelector } from "react-redux";
import { ChannelDataAction, MapDataAction } from "../../Redux/slices";
import { ViewershipMap } from "../../api/api";
import { isLogin } from "../../service/commonFunc";
import GraphIcon from '../../assets/svg/viewership/graph.svg';
import TableIcon from '../../assets/svg/viewership/table.svg';
import GraphView from '../../pages/viewership/MainVieweship/GraphView';
import TableView from '../../pages/viewership/MainVieweship/listView';
import 'chart.js/auto';

const Viewership = React.memo(() => {
  const [isActive, setIsActive] = useState("table");
  const dispatch = useDispatch();

  const {
    appThemeReducer: { appTheme },
    adminModeReducer: { adminMode },
  } = useSelector((state) => state);



  return (
    <div className={`content-body ${(adminMode || appTheme === "dark") ? "dark" : "light"}`}>
      <div className={`deshboard-content ${(adminMode || appTheme === "dark") ? "dark" : "light"}`}>
        <div className="title">
          <h2 style={{ marginTop: -20 }}>Viewership</h2>
        </div>

        <div className="navigation-view">
          <div className="buttonsNavigation" >
            <span
              className={isActive === "table" ? "active" : "" }
              onClick={() => setIsActive("table")}
               
            >
              <img src={TableIcon} alt="Table Icon" />
            </span>
            <span
              className={isActive === "graph" ? "active" : ""}
              onClick={() => setIsActive("graph")}
            >
              <img src={GraphIcon} alt="Graph Icon" />
            </span>
          </div>

          <div className="view-container " style={{marginTop:-35}}>
            <div className="flex-row">
              <div className="content-section" style={{ width: '100%' , }}>
                {isActive === "table" && <TableView />}
                {isActive === "graph" && <GraphView />}
              </div>
              <div className="active-user-section" style={{ marginTop: 100, width: '50%' }}>
                <ActiveUser />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
})

export default Viewership;

