import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import icroundsearch from "../../assets/svg/Assetmanagemant/search.svg";
import { Partner_search } from "../../api/api";
import './playlistsMain.scss'; 
import PlaylistFolder from "../../component/playlists/folder";

function PlaylistsMain() {
  const [searchQuery, setSearchQuery] = useState("");
  const [PartnerData, setPartnerData] = useState([]);
  const {
    appThemeReducer: { appTheme },
    adminModeReducer: { adminMode },
  } = useSelector((state) => state);

  const handleSearch = () => {
    partnerSearch(searchQuery);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      partnerSearch(searchQuery);
    }
  };

  const partnerSearch = async (searchQuery) => {
    const partnerDataSearch = await Partner_search(searchQuery);
    if (partnerDataSearch?.data && partnerDataSearch?.status === true) {
      setPartnerData(partnerDataSearch?.data);
    }
  };

  return (
    <>
      <div className={`content-body ${adminMode || appTheme === "dark" ? "dark" : "light"}`}>
        <div className={`deshboard-content ${adminMode || appTheme === "dark" ? "dark" : "light"}`}>
          <div className="title-search-container">
            <h2>Playlists</h2>
            <div className="search-container">
              <input
                id="search-input"
                className="search-box"
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  handleSearch(e.target.value);
                }}
                onKeyPress={handleKeyPress}
              />
              <button
                className="search-button"
                onClick={handleSearch}
                aria-label="Search"
              >
                <img
                  src={icroundsearch}
                  alt="Search"
                  className="search-icon"
                />
              </button>
            </div>
          </div>

          <div className="fixed-width-boxes">
            <div className="folders">
          <PlaylistFolder/>
            </div>
            <div className="sab-folder-list">
              {/* Content for the second box */}
              Second Box (Width: 442px)
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PlaylistsMain;
